import classNames from 'classnames';
import { Fragment } from 'react';

import { CfProductFilter } from '@mycs/contentful';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import Pillbox, { Tab } from 'mycs/shared/components/Pillbox/Pillbox';

import styles from 'mycs/shared/components/FilterableProducts/FilterableProducts.scss';

interface Props {
  filters: CfProductFilter[] | null;
  tabs: Tab[];
  activeTabName: string | null;
  isLoading: boolean;
  onTabChange: (activeTab: Tab) => void;
}

export default function DesktopFilterTabs(props: Props) {
  const { locale } = useLocale();
  const { filters, tabs, activeTabName, isLoading, onTabChange } = props;

  if (!filters) return <Fragment />;

  const activeTab = tabs.find((tab) => tab.id === activeTabName);

  return (
    <div className={classNames('contentContainer', styles.filterTabsContainer)}>
      <div className={styles.filterTabsTitle}>
        {I18nUtils.localize(locale, 'Filter')}
      </div>

      <div>
        <Pillbox
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={onTabChange}
          scrollableProps={{ wrap: true }}
          hideBorderBottom
          buttonStyle={classNames(styles.filterTab, {
            [styles.isDisabled]: isLoading,
          })}
        />
      </div>
    </div>
  );
}
