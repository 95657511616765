import classNames from 'classnames';
import { Fragment, memo } from 'react';
import { CfProductFilter, CfProductFilterOption } from '@mycs/contentful';

import CheckboxDialog from 'mycs/shared/components/CheckboxDialog/CheckboxDialog';
import ColorDialog from 'mycs/shared/components/ColorDialog/ColorDialog';
import DimensionsCheckboxDialog from 'mycs/shared/components/DimensionsCheckboxDialog/DimensionsCheckboxDialog';
import RangeSlidersDialog from 'mycs/shared/components/RangeSlidersDialog/RangeSlidersDialog';

import styles from 'mycs/shared/components/FilterableProducts/FilterableProducts.scss';

interface PricesDimensionsRanges {
  [key: string]: string;
}

interface Props {
  filters: CfProductFilter[] | null;
  activeTabName: string | null;
  isLoading: boolean;
  pricesDimensionsRanges: PricesDimensionsRanges | null;
  getAppliedOptions: (tabName: string) => CfProductFilterOption[];
  getActiveOptions: (tabName: string) => CfProductFilterOption[];
  getFilterOptions: (name: string) => CfProductFilterOption[];
  onUpdateActiveOptions: (
    options: CfProductFilterOption[],
    tabName: string
  ) => void;
  onResetActiveOptions?: (options: CfProductFilterOption[]) => void;
  onSubmitActiveOptions: () => void;
  onCancel: () => void;
}

export default memo(function DesktopFilterOptions({
  filters,
  activeTabName,
  isLoading,
  pricesDimensionsRanges,
  getAppliedOptions,
  getActiveOptions,
  getFilterOptions,
  onUpdateActiveOptions,
  onSubmitActiveOptions,
  onCancel,
  onResetActiveOptions,
}: Props) {
  const getOptionsElement = () => {
    if (!filters || isLoading || !pricesDimensionsRanges) return <Fragment />;

    const filterOptions = activeTabName
      ? getFilterOptions(activeTabName)
      : null;
    if (!filterOptions) return <Fragment />;

    switch (activeTabName) {
      case 'price':
        return (
          <RangeSlidersDialog
            ranges={pricesDimensionsRanges}
            options={filterOptions}
            appliedOptions={getAppliedOptions(activeTabName)}
            activeOptions={getActiveOptions(activeTabName)}
            onUpdateActiveOptions={onUpdateActiveOptions}
            onResetActiveOptions={onResetActiveOptions}
            onSubmitActiveOptions={onSubmitActiveOptions}
            onCancel={onCancel}
            type="price"
            tabName={activeTabName}
          />
        );
      case 'dimensions':
        const isCheckboxLayout =
          filterOptions.filter((option) => option.isBoolean).length > 0;

        return isCheckboxLayout ? (
          <DimensionsCheckboxDialog
            options={filterOptions}
            appliedOptions={getAppliedOptions(activeTabName)}
            onUpdateActiveOptions={onUpdateActiveOptions}
            onResetActiveOptions={onResetActiveOptions}
            onSubmitActiveOptions={onSubmitActiveOptions}
            onCancel={onCancel}
            tabName={activeTabName}
          />
        ) : (
          <RangeSlidersDialog
            ranges={pricesDimensionsRanges}
            options={filterOptions}
            appliedOptions={getAppliedOptions(activeTabName)}
            activeOptions={getActiveOptions(activeTabName)}
            onUpdateActiveOptions={onUpdateActiveOptions}
            onResetActiveOptions={onResetActiveOptions}
            onSubmitActiveOptions={onSubmitActiveOptions}
            onCancel={onCancel}
            type="dimensions"
            tabName={activeTabName}
          />
        );
      case 'color':
        return (
          <ColorDialog
            options={filterOptions}
            appliedOptions={getAppliedOptions(activeTabName)}
            onUpdateActiveOptions={onUpdateActiveOptions}
            onResetActiveOptions={onResetActiveOptions}
            onSubmitActiveOptions={onSubmitActiveOptions}
            onCancel={onCancel}
            tabName={activeTabName}
          />
        );
      case 'material':
      case 'shape':
      case 'model':
        return (
          <CheckboxDialog
            options={filterOptions}
            appliedOptions={getAppliedOptions(activeTabName)}
            onUpdateActiveOptions={onUpdateActiveOptions}
            onResetActiveOptions={onResetActiveOptions}
            onSubmitActiveOptions={onSubmitActiveOptions}
            onCancel={onCancel}
            tabName={activeTabName}
          />
        );
      default:
        return <Fragment />;
    }
  };

  return (
    <div
      className={classNames(styles.filterOptionsContainer, {
        [styles.showOptions]: activeTabName && !isLoading,
      })}
    >
      {getOptionsElement()}
    </div>
  );
});
