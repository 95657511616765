import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _xor from 'lodash/xor';
import _xorWith from 'lodash/xorWith';

import { CfProductFilterOption } from '@mycs/contentful';
import {
  ExtendedFilterOption,
  PricesDimensionsRanges,
} from './filterable-products-helpers';
import { Tab } from 'mycs/shared/components/Pillbox/Pillbox';
import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import DesktopFilterOptions from 'mycs/shared/components/FilterableProducts/DesktopFilterOptions/DesktopFilterOptions';
import DesktopFilterTabs from 'mycs/shared/components/FilterableProducts/DesktopFilterTabs/DesktopFilterTabs';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import MobileFilters from 'mycs/shared/components/FilterableProducts/MobileFilters/MobileFilters';
import Sticky from 'mycs/shared/components/Sticky/Sticky';

import styles from './FilterableProducts.scss';

export type ExtendedFilter = {
  _id: string;
  _contentType: 'productFilter';
  filterOptions: ExtendedFilterOption[];
  slug?: string | undefined;
  label: string;
  filterName:
    | 'price'
    | 'dimensions'
    | 'color'
    | 'material'
    | 'shape'
    | 'model'
    | 'style';
};

export function ProductFilters({
  productsContainerRef,
  filters,
  filterTabs,
  filterUndockElementOffset,
  pricesDimensionsRanges,
  formattedOptions,
  activeOptions,
  appliedOptions,
  isMobileFilterOpen,
  activeTabName,
  isLoading,
  onTabChange,
  onUpdateActiveOptions,
  onResetActiveOptions,
  onSubmitActiveOptions,
  onCancel,
  onSubmitActiveOptionsMobile,
  toggleMobileFilter,
}: {
  productsContainerRef: any;
  filters: ExtendedFilter[];
  filterTabs: Tab[];
  filterUndockElementOffset?: number;
  pricesDimensionsRanges: PricesDimensionsRanges | null;
  formattedOptions: any;
  activeOptions: ExtendedFilterOption[];
  appliedOptions: ExtendedFilterOption[];
  isMobileFilterOpen: boolean;
  activeTabName: string | null;
  isLoading: boolean;
  onTabChange: (activeTab: Tab) => void;
  onUpdateActiveOptions: (currentActiveOptions: ExtendedFilterOption[]) => void;
  onResetActiveOptions: (currentActiveOptions: ExtendedFilterOption[]) => void;
  onSubmitActiveOptions: () => void;
  onCancel: () => void;
  onSubmitActiveOptionsMobile: () => void;
  toggleMobileFilter: (isOpen: boolean) => void;
}) {
  const { hasPhoneDimensions } = useDevice();
  const { locale } = useLocale();

  if (hasPhoneDimensions) {
    return (
      <MobileFilters
        undockElementOffset={filterUndockElementOffset}
        filters={filters}
        tabs={filterTabs}
        title={getFilterTitle(formattedOptions)}
        activeOptions={activeOptions}
        appliedOptions={appliedOptions}
        isMobileFilterOpen={isMobileFilterOpen}
        productsContainerRef={productsContainerRef}
        pricesDimensionsRanges={pricesDimensionsRanges}
        toggleMobileFilter={toggleMobileFilter}
        getAppliedOptions={getAppliedOptions}
        getActiveOptions={getActiveOptions}
        getFilterOptions={getFilterOptions}
        onUpdateActiveOptions={onUpdateActiveOptionsMobile}
        onResetActiveOptions={onResetActiveOptionsMobile}
        onSubmitActiveOptions={onSubmitActiveOptionsMobile}
      />
    );
  }

  return (
    <div className={styles.desktopFiltersContainer}>
      <Sticky
        dockedClassName={styles.filterTabsStickyContainer}
        undockElement={productsContainerRef}
        undockElementOffset={filterUndockElementOffset}
      >
        <DesktopFilterTabs
          filters={filters}
          tabs={filterTabs}
          activeTabName={activeTabName}
          isLoading={isLoading}
          onTabChange={onTabChange}
        />

        <DesktopFilterOptions
          filters={filters}
          activeTabName={activeTabName}
          isLoading={isLoading}
          pricesDimensionsRanges={pricesDimensionsRanges}
          getAppliedOptions={getAppliedOptions}
          getActiveOptions={getActiveOptions}
          getFilterOptions={getFilterOptions}
          onUpdateActiveOptions={onUpdateActiveOptionsDesktop}
          onResetActiveOptions={onResetActiveOptionsDesktop}
          onSubmitActiveOptions={onSubmitActiveOptions}
          onCancel={onCancel}
        />
      </Sticky>
    </div>
  );

  /**
   * Get filter title containing the number of filters applied
   */
  function getFilterTitle(formattedOptions: any) {
    return formattedOptions
      ? `${I18nUtils.localize(locale, 'Filter')} (${formattedOptions.length})`
      : I18nUtils.localize(locale, 'Filter');
  }

  /**
   * Get applied options by tab name
   */
  function getAppliedOptions(tabName: string): CfProductFilterOption[] {
    return appliedOptions.filter((option) => option.tabName === tabName);
  }

  /**
   * Get active options by tab name
   */
  function getActiveOptions(tabName: string): CfProductFilterOption[] {
    return activeOptions.filter((option) => option.tabName === tabName);
  }

  /**
   * Get filter options by filter name
   */
  function getFilterOptions(name: string): CfProductFilterOption[] {
    if (!filters) return [];

    const filter = filters.find((filter) => filter.filterName === name);
    if (!filter) return [];

    return filter.filterOptions;
  }

  /**
   * Update active options
   */
  function onUpdateActiveOptionsDesktop(
    options: ExtendedFilterOption[],
    tabName: string
  ) {
    const _activeOptions = _cloneDeep(activeOptions);
    const _appliedOptions =
      _activeOptions && _activeOptions.length > 0
        ? _activeOptions
        : _cloneDeep(appliedOptions);
    let currentActiveOptions: ExtendedFilterOption[] = [];

    // If the options are range options, replace active options
    if (['price', 'dimensions'].includes(tabName)) {
      options.forEach((option) => {
        const indexOptionExists = _appliedOptions.findIndex(
          (activeOption) => activeOption.queryParam === option.queryParam
        );
        if (indexOptionExists < 0) {
          _appliedOptions.push(option);
          currentActiveOptions = _appliedOptions;
        } else {
          _appliedOptions.splice(indexOptionExists, 1);
          _appliedOptions.push(option);
          currentActiveOptions = _appliedOptions;
        }
      });
      // Otherwise, update active options
    } else {
      currentActiveOptions = _xorWith(_appliedOptions, options, _isEqual);
    }

    onUpdateActiveOptions(currentActiveOptions);
  }

  /**
   * Update active options for mobile filters
   */
  function onUpdateActiveOptionsMobile(
    options: CfProductFilterOption[],
    tabName: string
  ) {
    const _activeOptions = _cloneDeep(activeOptions);
    let currentActiveOptions = [];

    // If the options are range options, replace active options
    if (['price', 'dimensions'].includes(tabName)) {
      const optionsPerTabName = _activeOptions.filter(
        (appliedOption) => appliedOption.tabName === tabName
      );
      if (optionsPerTabName.length > 0) {
        currentActiveOptions = [
          ..._xor(_activeOptions, optionsPerTabName),
          ...options,
        ];
      } else {
        currentActiveOptions = _xorWith(_activeOptions, options, _isEqual);
      }
      // Otherwise, update active options
    } else {
      currentActiveOptions = _xorWith(_activeOptions, options, _isEqual);
    }

    onUpdateActiveOptions(currentActiveOptions);
  }

  /**
   * Remove options from active options per tab
   */
  function onResetActiveOptionsDesktop(options: CfProductFilterOption[]) {
    const _activeOptions = _cloneDeep(activeOptions);
    const _appliedOptions =
      _activeOptions && _activeOptions.length > 0
        ? _activeOptions
        : _cloneDeep(appliedOptions);
    const currentActiveOptions = _xorWith(_appliedOptions, options, _isEqual);

    onResetActiveOptions(currentActiveOptions);
  }

  /**
   * Remove all of the options from active options
   */
  function onResetActiveOptionsMobile() {
    onResetActiveOptions([]);
  }
}
