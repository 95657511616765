import styles from './ProductPreviewPlaceholder.scss';

export default function ProductPreviewPlaceholder() {
  return (
    <div className={styles.container}>
      <div className={styles.imageBlock}></div>
      <div className={styles.textBlock}>
        <div className={styles.titlePlaceholder}></div>
        <div className={styles.descriptionPlaceholder}></div>
      </div>
    </div>
  );
}
