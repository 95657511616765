import { useLocation } from 'react-router-dom';

import { CfPageMain } from '@mycs/contentful';
import {
  getPageContentByKeyFromReduxState,
  getStatusCodeByKeyFromReduxState,
} from 'mycs/shared/state/slices/pageSlice';
import { LegacyTextBlock } from 'mycs/shared/components/TextBlock/TextBlock';
import {
  RenderContent,
  shouldRenderLegacySeoText,
} from 'mycs/landing-page/RenderContent/RenderContent';
import { TrackMainPageSubcategories } from './TrackMainPageSubcategories';
import { useAppSelector } from 'mycs/shared/state/store';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import CfEdit from 'mycs/shared/components/CfEdit/CfEdit';
import Page404 from 'mycs/404/components/Page404/Page404';

import placeholder from './placeholder-data';

export default function MainPage() {
  const { locale } = useLocale();
  const { pathname } = useLocation();

  const data =
    useAppSelector((state) =>
      getPageContentByKeyFromReduxState<CfPageMain>(state, {
        locale,
        pathname,
      })
    ) ?? (placeholder as unknown as CfPageMain);
  const statusCode = useAppSelector(
    (state) =>
      getStatusCodeByKeyFromReduxState(state, {
        locale,
        pathname,
      }) || 200
  );

  // The data can be both undefined and null,
  // so don't use the default value in a destructuring assignment

  if (statusCode === 404) {
    return <Page404 data={data} />;
  }

  const seoTextContent = data.seoHtml || '';

  return (
    <div>
      <TrackMainPageSubcategories locale={locale} data={data} />

      {data.content?.map((content, index) => (
        <RenderContent key={`${content._id}-${index}`} content={content} />
      ))}

      {shouldRenderLegacySeoText(data) && seoTextContent && (
        <LegacyTextBlock htmlContent={seoTextContent} layout="seo" readMore />
      )}

      <CfEdit entryId={data._id} />
    </div>
  );
}
