import { PureComponent } from 'react';
import Swipeable from 'react-swipeable';
import classNames from 'classnames';

import Modal from 'mycs/shared/components/Modal/Modal';

import styles from './MediaModal.scss';

type Props = {
  headerContent: string;
  sidebarContent: React.ReactNode;
  mainContent: React.ReactNode;
  activeIndex: number;
  totalItems: number;
  isOpen: boolean;
  onClose: () => void;
  onChange: (index: number) => void;
  productLayout: boolean;
  className?: string;
};
export default class MediaModal extends PureComponent<Props> {
  static defaultProps = {
    activeIndex: 0,
    totalItems: 0,
    isOpen: false,
    productLayout: false,
  };

  /**
   * Switch to previous/next item
   */
  switchItem = (step: number) => {
    const { activeIndex, totalItems, onChange } = this.props;
    const firstIndex = 0;
    const lastIndex = totalItems;
    let index = activeIndex + step;

    if (activeIndex === firstIndex && step === -1) {
      index = lastIndex;
    }
    if (activeIndex === lastIndex && step === 1) {
      index = firstIndex;
    }
    onChange(index);
  };

  render() {
    const {
      headerContent,
      sidebarContent,
      mainContent,
      totalItems,
      isOpen,
      onClose,
      productLayout,
    } = this.props;

    const multipleItems = totalItems > 1;

    const arrows = (
      <div>
        <div
          className="mx-arrow mx-arrow--fixed-left"
          onClick={() => this.switchItem(-1)}
          data-testid="mx-arrow--fixed-left"
        />
        <div
          className="mx-arrow mx-arrow--fixed-right"
          onClick={() => this.switchItem(+1)}
          data-testid="mx-arrow--fixed-right"
        />
      </div>
    );

    const classes = classNames(
      styles.panelContainer,
      {
        [styles.productLayout]: productLayout,
      },
      this.props?.className
    );

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header={headerContent}
        mediaLayout
        overlayClass="mediaModalOverlay"
        clickOutsideWhiteList={[
          '[class*="contactFormModalOverlay"]',
          '[class*="Popover"]',
        ]}
      >
        <Swipeable
          onSwipedLeft={() => this.switchItem(+1)}
          onSwipedRight={() => this.switchItem(-1)}
        >
          <div className={classes}>
            <div className={classNames('row', styles.panelBody)}>
              <div
                className={classNames('col-xs-12 col-sm-4', styles.panelColumn)}
              >
                <div
                  className={classNames(
                    styles.panelSidebarContent,
                    styles.hideOnSmallScreen
                  )}
                >
                  {sidebarContent}
                </div>
              </div>

              <div
                className={classNames(
                  'col-xs-12 col-sm-8',
                  styles.panelColumn,
                  styles.panelFull
                )}
              >
                {mainContent}
              </div>
            </div>
          </div>

          {multipleItems ? <div>{arrows}</div> : null}
        </Swipeable>
      </Modal>
    );
  }
}
