import useSWR from 'swr';

import { useLocale } from 'mycs/shared/state/LocaleContext';
import DesignApiService from 'mycs/shared/services/DesignApiService/DesignApiService';
import Logger from 'mycs/shared/services/Logger';
import { PricesDimensionsRanges } from './filterable-products-helpers';

/**
 * Get min & max ranges for prices and dimensions
 */
export function usePricesDimensionsRanges(furnitureGroup: string) {
  const { countryCode } = useLocale();
  const { data: pricesDimensionsRanges } = useSWR<PricesDimensionsRanges>(
    'pricesDimensionRanges',
    () => {
      return DesignApiService.getPricesDimensionsRanges(
        furnitureGroup,
        countryCode
      ).catch((err) => {
        Logger.error(
          "<FilterableProducts>: Couldn't find prices and dimensions ranges",
          err
        );
        return null;
      });
    }
  );

  return pricesDimensionsRanges || null;
}
