import { CfHeroBanner, CfHeaderImage, CfHeroBlock } from '@mycs/contentful';

import HeaderImage from 'mycs/shared/components/HeaderImage/HeaderImage';
import HeaderVideo from 'mycs/shared/components/HeaderVideo/HeaderVideo';
import HeaderImageCarousel from 'mycs/shared/components/HeaderImageCarousel/HeaderImageCarousel';
import HeroBlock from 'mycs/shared/components/HeroBlock/HeroBlock';

import styles from './HeroBanner.scss';

// FIXME: heroBlockPosition field should not be an array
type CustomCfHeroBanner = CfHeroBanner & {
  heroBlockPosition?: ('Top' | 'Bottom')[];
};

export default function HeroBanner({
  content,
}: {
  content: CustomCfHeroBanner;
}) {
  function renderHeaderImage(
    headerImage: CfHeaderImage,
    onlyDisplayTitlesFromHeroImage = false
  ) {
    // Render the component without an image, only text
    if (onlyDisplayTitlesFromHeroImage) {
      return (
        <HeaderImage
          heading={headerImage.heading}
          blurb={headerImage.blurb}
          textColour={headerImage.textColour}
          id={content.anchor}
        />
      );
    }

    // A single header image
    return (
      <HeaderImage
        image={headerImage.image}
        imageTablet={headerImage.imageTablet}
        imagePhone={headerImage.imagePhone}
        lowresUrl={headerImage.imageBase64}
        anchors={headerImage.anchors}
        heading={headerImage.heading}
        blurb={headerImage.blurb}
        button={headerImage.button}
        headlineBar={headerImage.headlineBar}
        headlineBarImage={headerImage.headlineBarImage}
        textColour={headerImage.textColour}
        isCentered={content.layout === 'centered'}
        id={content.anchor}
      />
    );
  }

  function renderHeaderImagesCarousel(
    headerImages: CfHeaderImage[],
    onlyDisplayTitlesFromHeroImage = false
  ) {
    // Only one image
    if (headerImages.length === 1) {
      return renderHeaderImage(headerImages[0], onlyDisplayTitlesFromHeroImage);
    }

    // Header Images Carousel
    return (
      <div className={styles.bannerCarouselContainer} id={content.anchor}>
        <HeaderImageCarousel
          images={headerImages}
          isCentered={content.layout === 'centered'}
        />
      </div>
    );
  }

  function renderHeroBlocks(
    heroBlocks: CfHeroBlock[],
    position?: 'Top' | 'Bottom'
  ) {
    // Only one HeroBlock
    if (heroBlocks.length === 1) {
      const data = heroBlocks[0];
      return <HeroBlock content={{ ...data, position }} />;
    }

    // Multiple HeroBlock's
    return (
      <div className={styles.mainContainer}>
        {heroBlocks.map((data) => (
          <div className={styles.container} key={data._id}>
            <HeroBlock content={{ ...data, position }} />
          </div>
        ))}
      </div>
    );
  }

  if (content.videos) {
    return <HeaderVideo video={content.videos[0]} />;
  }

  if (content.headerImage) {
    return renderHeaderImage(
      content.headerImage,
      content.onlyDisplayTitlesFromHeroImage
    );
  }

  if (content.headerImagesCarousel) {
    return renderHeaderImagesCarousel(
      content.headerImagesCarousel,
      content.onlyDisplayTitlesFromHeroImage
    );
  }

  if (content.heroBlocks) {
    return renderHeroBlocks(content.heroBlocks, content.heroBlockPosition?.[0]);
  }

  return <></>;
}

export function LegacyHeroBanner(
  props: Omit<CustomCfHeroBanner, '_id' | '_contentType' | 'slug' | 'layout'>
) {
  return (
    <HeroBanner
      content={{
        ...props,
        _id: 'legacyHeroBanner',
        _contentType: 'wizardPage',
        slug: 'legacyHeroBanner',
        layout: 'default',
      }}
    />
  );
}
