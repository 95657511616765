import { useEffect, useCallback } from 'react';

import classNames from 'classnames';
import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import AddToCartButton from 'mycs/shared/components/AddToCartButton/AddToCartButton';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import MediaModal from 'mycs/shared/components/MediaModal/MediaModal';
import ProductPreview from 'mycs/shared/components/ProductPreview/ProductPreview';
import SmartImage from 'mycs/shared/components/SmartImage/SmartImage';
import ContactForm from 'mycs/shared/components/ContactForm/ContactForm';
import ContactStore from 'mycs/shared/stores/ContactStore/ContactStore';
import styles from 'mycs/shared/components/ProductModal/ProductModal.scss';
import { useLocation } from 'react-router-dom';
import Button from 'mycs/shared/components/Button/Button';

interface Props {
  activeItem: any;
  activeIndex: number;
  totalItems: number;
  isOpen: boolean;
  onClose: () => void;
  onChange: (activeIndex: number) => void;
  className?: string;
  layout?: 'legacy';
}

function ProductCarouselModal(props: Props) {
  const device = useDevice();
  const isTablet = device.isTablet || device.hasTabletDimensions;
  const { locale } = useLocale();
  const { pathname } = useLocation();
  const {
    activeItem,
    activeIndex,
    totalItems,
    isOpen,
    onClose,
    onChange,
    className,
    layout,
  } = props;

  // Function to handle arrow key presses
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'ArrowRight') {
        onChange((activeIndex + 1) % (totalItems + 1));
      } else if (e.key === 'ArrowLeft') {
        onChange((activeIndex - 1 + totalItems + 1) % (totalItems + 1));
      }
    },
    [activeIndex, totalItems, onChange]
  );

  // Add and remove the event listener for keydown events
  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, activeIndex, totalItems, handleKeyDown]);

  const renderPhotoCredits = () => {
    const { activeItem } = props;

    return (
      <div className={styles.photoCreditsSection}>
        <div className={styles.panelPhotoBy}>
          {I18nUtils.localize(locale, 'Interior photo by')}
        </div>
        <h2 className={styles.panelVendor}>
          {activeItem.credits || I18nUtils.localize(locale, 'MYCS Berlin')}
        </h2>
      </div>
    );
  };

  const toggleContactForm = () => {
    ContactStore.toggle(locale, pathname);
  };

  const renderModalSidebarContent = () => {
    const { activeItem } = props;
    // Sizes for SmartImage, the size is forced to use 500*500 resolution
    const imageSizes = '(max-width: 767px) 10vw, 20vw';
    const showContactForm = layout === 'legacy' && !activeItem.uuids;

    const designs = activeItem.designs
      ? activeItem.designs.map((design: any, index: number) => (
          <div className={styles.panelProductContainer} key={index}>
            <ProductPreview
              product={design}
              isPdpLink={true}
              imageSizes={imageSizes}
              isMobileVersion={isTablet}
            />

            <AddToCartButton
              className={classNames(
                styles.showOnMobile,
                styles.button,
                styles.cartButton
              )}
              product={design}
              mobileIconOnly={false}
            />
          </div>
        ))
      : null;

    return (
      <div>
        {layout === 'legacy' && showContactForm && (
          <div className={styles.hideOnSmallScreen}>
            <ContactForm
              isOpen={showContactForm}
              requestClose={toggleContactForm}
              showFooter
            />
            <Button
              className={classNames(styles.button, styles.buttonMarginTop)}
              isPrimaryCta
              onClick={toggleContactForm}
              text="Available on request"
            />
          </div>
        )}
        {designs}
        {layout !== 'legacy' && (
          <div className={styles.panelInfo}>
            {activeItem.shortDescription ? (
              <h5 className={styles.panelInfoTitle}>
                {activeItem.shortDescription}
              </h5>
            ) : null}

            {activeItem.longDescription ? (
              <p className={styles.panelInfoDescriptionLeft}>
                {activeItem.longDescription}
              </p>
            ) : null}
          </div>
        )}

        <div className={styles.panelLogoInfoCol}>{renderPhotoCredits()}</div>
      </div>
    );
  };

  const renderModalMainContent = () => {
    const { activeItem } = props;
    const showContactForm = layout === 'legacy' && !activeItem.uuids;

    // Sizes for SmartImage, the size is forced to use 500*500 resolution
    const imageSizes = '(max-width: 767px) 10vw, 20vw';
    const designs = activeItem.designs
      ? activeItem.designs.map((design: any, index: number) => (
          <div className={styles.panelProductContainer} key={index}>
            <ProductPreview
              product={design}
              imageSizes={imageSizes}
              isPdpLink={true}
              isMobileVersion={isTablet}
            />

            <AddToCartButton
              className={classNames(styles.button, styles.cartButton)}
              product={design}
              mobileIconOnly={false}
            />
          </div>
        ))
      : null;

    return (
      <div>
        <SmartImage
          alt={activeItem.title || ''}
          className={styles.panelPictureWide}
          src={activeItem.fullImage && activeItem.fullImage.url}
          title={activeItem.title || ''}
        />
        {layout === 'legacy' && (
          <div className={styles.panelInfo}>
            {activeItem.shortDescription ? (
              <h5 className={styles.panelInfoTitle}>
                {activeItem.shortDescription}
              </h5>
            ) : null}

            {activeItem.longDescription ? (
              <p className={styles.panelInfoDescriptionLeft}>
                {activeItem.longDescription}
              </p>
            ) : null}

            {!activeItem.uuids && (
              <div className={styles.showOnSmallScreen}>
                <ContactForm
                  isOpen={showContactForm}
                  requestClose={toggleContactForm}
                  showFooter
                />
                <Button
                  className={classNames(styles.button, styles.buttonMarginTop)}
                  isPrimaryCta
                  onClick={toggleContactForm}
                  text="More options available"
                />
              </div>
            )}
          </div>
        )}

        {layout !== 'legacy' && (
          <div className={styles.showOnSmallScreen}>
            {renderPhotoCredits()} {designs}
          </div>
        )}
      </div>
    );
  };

  if (!activeItem) return null;

  return (
    <MediaModal
      headerContent={activeItem.title}
      sidebarContent={renderModalSidebarContent()}
      mainContent={renderModalMainContent()}
      activeIndex={activeIndex}
      totalItems={totalItems}
      isOpen={isOpen}
      onClose={onClose}
      onChange={onChange}
      productLayout
      className={className}
    />
  );
}

export default ProductCarouselModal;
