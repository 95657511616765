import { Component } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import xorWith from 'lodash/xorWith';
import Button from 'mycs/shared/components/Button/Button';
import Checkbox from 'mycs/shared/components/Checkbox/Checkbox';
import styles from './DimensionsCheckboxDialog.scss';

interface FilterOption {
  slug?: string;
  queryParam: string;
  queryParamValue: string;
  isBoolean?: boolean;
  label?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  tabName?: string | null;
}

interface DimensionCheckboxOption {
  checkboxLabel: string;
  checkboxOptions: FilterOption[];
}

interface Props {
  options: FilterOption[];
  appliedOptions?: FilterOption[];
  activeOptions?: FilterOption[];
  onUpdateActiveOptions?: (options: FilterOption[], tabName: string) => void;
  onResetActiveOptions?: (options: FilterOption[]) => void;
  onSubmitActiveOptions?: () => void;
  onCancel?: () => void;
  mobileLayout?: boolean;
  tabName: string;
}

interface State {
  currentOptions: FilterOption[];
}

export default class DimensionsCheckboxDialog extends Component<Props, State> {
  static defaultProps = {
    mobileLayout: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      currentOptions: [],
    };
  }

  /**
   * Get formatted list of options (grouped by label)
   */
  getCheckboxesOptions() {
    const checkboxesOptions: DimensionCheckboxOption[] = [];

    this.props.options.forEach((filterOption: FilterOption) => {
      const index = checkboxesOptions.findIndex(
        (option: DimensionCheckboxOption) =>
          option.checkboxLabel === filterOption.label
      );
      if (index >= 0) {
        checkboxesOptions[index].checkboxOptions.push(filterOption);
      } else {
        checkboxesOptions.push({
          checkboxLabel: filterOption.label || '',
          checkboxOptions: [filterOption],
        });
      }
    });

    return checkboxesOptions;
  }

  onChange = (currentOption: DimensionCheckboxOption) => {
    let currentOptions: FilterOption[] = [];
    // Toggle option if already selected
    if (!this.isChecked(currentOption.checkboxOptions)) {
      currentOptions = currentOption.checkboxOptions;
    }
    this.setState({ currentOptions }, () => {
      if (this.props.onUpdateActiveOptions) {
        this.props.onUpdateActiveOptions(currentOptions, this.props.tabName);
      }
    });
  };

  onConfirm = () => {
    if (this.props.onSubmitActiveOptions) {
      this.props.onSubmitActiveOptions();
    }
  };

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onReset = () => {
    if (this.props.onResetActiveOptions) {
      this.props.onResetActiveOptions(this.state.currentOptions);
    }
    this.setState({ currentOptions: [] });
  };

  isChecked = (options: FilterOption[]) => {
    return isEmpty(xorWith(this.state.currentOptions, options, isEqual));
  };

  isConfirmDisabled = () => {
    const isOptionSelectedApplied = isEmpty(
      xorWith(this.props.appliedOptions, this.state.currentOptions, isEqual)
    );
    return isOptionSelectedApplied;
  };

  initActiveOption = () => {
    if (!this.props.appliedOptions) return;
    if (this.props.appliedOptions.length > 0) {
      this.setState({ currentOptions: this.props.appliedOptions });
    }
  };

  initActiveOptionMobile = () => {
    if (!this.props.activeOptions) return;
    this.setState({ currentOptions: this.props.activeOptions });
  };

  componentDidMount() {
    if (this.props.mobileLayout) {
      this.initActiveOptionMobile();
    } else {
      this.initActiveOption();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.mobileLayout) {
      if (this.props.activeOptions !== prevProps.activeOptions) {
        this.initActiveOptionMobile();
      }
    } else {
      if (this.props.appliedOptions !== prevProps.appliedOptions) {
        this.initActiveOption();
      }
    }
  }

  render(): JSX.Element {
    const { mobileLayout } = this.props;

    return (
      <div
        className={classNames(styles.container, {
          [styles.mobileLayout]: mobileLayout,
        })}
      >
        <div className={styles.closeButton}>
          <Button onClick={this.onCancel} iconName="general/close.svg" />
        </div>

        <div className={styles.checkboxOptionsContainer}>
          {this.getCheckboxesOptions().map(
            (option: DimensionCheckboxOption, index: number) => (
              <div className={styles.checkboxOptionContainer} key={index}>
                <Checkbox
                  modernLayout
                  isBig
                  label={option.checkboxLabel}
                  nativeProps={{
                    id: option.checkboxLabel,
                    name: option.checkboxLabel,
                  }}
                  checked={this.isChecked(option.checkboxOptions)}
                  onChange={() => this.onChange(option)}
                />
              </div>
            )
          )}
        </div>

        <div className={styles.confirmButton}>
          <Button
            onClick={this.onConfirm}
            text="Confirm"
            isPrimaryCta
            isDisabled={this.isConfirmDisabled()}
          />
        </div>
      </div>
    );
  }
}
