import CollapsingPanel from 'mycs/shared/components/CollapsingPanel/CollapsingPanel';
import {
  CfStaticPageCategory,
  CfStaticPageCategoryItem,
} from '@mycs/contentful';
import styles from './QACategory.scss';

interface Props {
  content: CfStaticPageCategory;
}
export default function QACategory({ content }: Props) {
  if (!content?.title || !content?.questions) return null;
  return (
    <div className={styles.category} id={content.anchor}>
      <h3 className={styles.categoryTitle}>{content.title}</h3>
      {(content.questions || []).map(
        (question: CfStaticPageCategoryItem, key: number) => (
          <div key={key}>
            <CollapsingPanel
              headline={question.question}
              content={question.answer}
            />
          </div>
        )
      )}
    </div>
  );
}
