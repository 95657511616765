import { useState } from 'react';
import classNames from 'classnames';

import { CfVideo } from '@mycs/contentful';
import { useDevice } from 'mycs/router/DeviceContext';
import Button from 'mycs/shared/components/Button/Button';
import Localize from 'mycs/shared/components/Localize/Localize';
import SafeText from 'mycs/shared/components/SafeText/SafeText';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';
import VideoOverlay from 'mycs/shared/components/VideoOverlay/VideoOverlay';

import styles from './HeaderVideo.scss';

export default function HeaderVideo(props: { video: CfVideo }) {
  const [isVideoOverlayOpened, setIsVideoOverlayOpened] = useState(false);
  const { hasPhoneDimensions } = useDevice();
  const {
    textColour = 'Dark',
    video,
    videoTablet,
    videoMobile,
    videoPoster,
    hideOnMobile = false,
    buttonText,
    buttonLink,
    text: subtitle,
    title,
  } = props.video;

  const colorStyles =
    textColour === 'Dark' ? styles.darkMode : styles.lightMode;
  const selectedVideo =
    (hasPhoneDimensions ? videoMobile || videoTablet : video) || video;
  const videoUrl = selectedVideo.url;

  const posterBackgroundImage = videoPoster
    ? `url(${UrlProviderService.getImageUrl(
        videoPoster.url,
        videoPoster.width,
        videoPoster.height
      )})`
    : undefined;

  /**
   * Display the video overlay on click on play
   */
  function onVideoOverlayOpen() {
    setIsVideoOverlayOpened(true);
  }

  /**
   * Close the video overlay on click on close
   */
  function onVideoOverlayClose() {
    setIsVideoOverlayOpened(isVideoOverlayOpened);
  }

  return (
    <div className={classNames(styles.container, colorStyles)}>
      {hasPhoneDimensions && !videoMobile ? (
        <div
          className={styles.overlayImage}
          style={{
            backgroundImage: posterBackgroundImage,
          }}
        />
      ) : (
        <div className={styles.videoContainer}>
          <video
            className={styles.video}
            src={videoUrl}
            autoPlay
            muted
            loop
            playsInline
          />
        </div>
      )}

      <div className={styles.textContainer}>
        <div className={styles.textLayer}>
          {title && (
            <h3 className={styles.title}>
              <SafeText content={title} />
            </h3>
          )}
          {subtitle && (
            <SafeText content={subtitle} className={styles.subtitle} />
          )}

          {hasPhoneDimensions && !hideOnMobile && (
            <div className={styles.playContainer}>
              <div className={styles.playIcon} onClick={onVideoOverlayOpen}>
                <Button
                  iconName="general/play.svg"
                  className={styles.playIconContainer}
                />
              </div>
              <div className={styles.playText} onClick={onVideoOverlayOpen}>
                <Localize>Play video</Localize>
              </div>
            </div>
          )}

          {buttonText && buttonLink && (
            <div className={styles.button}>
              <Button
                isPrimaryCta
                text={buttonText}
                href={buttonLink}
                trackLabel={'CTA'}
                nativeProps={{
                  target: getLinkTarget(buttonLink),
                }}
              />
            </div>
          )}
        </div>
      </div>

      {isVideoOverlayOpened && (
        <VideoOverlay
          video={videoUrl}
          isOpen={isVideoOverlayOpened}
          onClose={onVideoOverlayClose}
          darkLayout
        />
      )}
    </div>
  );
}

/**
 * Get a target attribute value depending on the URL
 */
function getLinkTarget(url: string) {
  const isExternalLink = /utm_source=/.test(url);
  return isExternalLink ? '_blank' : undefined;
}
