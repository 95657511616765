import { Fragment, useState } from 'react';
import classNames from 'classnames';
import { CfRibbon } from '@mycs/contentful';

import { ResponsiveImage } from 'mycs/shared/components/ResponsiveImage/ResponsiveImage';
import { useRibbon } from 'mycs/hooks/useRibbon';
import Alink from 'mycs/shared/components/Alink/Alink';
import Button from 'mycs/shared/components/Button/Button';
import ClickOutside from 'mycs/shared/components/ClickOutside/ClickOutside';
import Icon from 'mycs/shared/components/Icon/Icon';
import SafeText from 'mycs/shared/components/SafeText/SafeText';

import styles from './PromoBanner.scss';

// This prop currently used is only for testing purposes to build component in the Styleguide
interface Props {
  ribbon?: CfRibbon;
}

const imageSizes =
  '(max-width: 767px) 100vw, (min-width: 768px) and (max-width: 992px) 50vw, 70vw';

const backgroundColours: { [key: string]: string } = {
  green: 'greenBackground',
  red: 'redBackground',
  lightRed: 'lightRedBackground',
  grey: 'greyBackground',
  yellow: 'yellowBackground',
  blue: 'blueBackground',
};

export default function PromoBanner(props: Props) {
  const [isCollapsed, setCollapsed] = useState(false);
  const stateRibbon = useRibbon();
  const ribbon = props.ribbon ? props.ribbon : stateRibbon;

  if (!ribbon || !ribbon.isPromoBannerActivated) return <Fragment />;

  const {
    imageDesktop,
    imageTablet,
    imagePhone,
    promoBannerInfoText,
    linkText,
    promoBannerButton,
    promoBannerBackgroundColour,
  } = ribbon;

  const countdownTitles = ribbon.promoCountdownTitles || ribbon.countdownTitles;

  const styleBackground = promoBannerBackgroundColour
    ? backgroundColours[promoBannerBackgroundColour]
    : backgroundColours.green;

  return (
    <div
      className={classNames(
        'contentContainer',
        styles.PromoBannerContainer,
        styles[styleBackground]
      )}
    >
      <div className={styles.fullWidth}>
        {countdownTitles && (
          <div className={styles.promoText}>
            <SafeText
              className={styles.subheadline}
              content={countdownTitles[0]}
            />

            <SafeText
              className={styles.headline}
              content={countdownTitles[1]}
            />

            {promoBannerButton && (
              <Button
                className={styles.promoBannerButton}
                href={promoBannerButton.url}
                text={promoBannerButton.text}
                isPrimaryCta
              />
            )}

            {linkText && (
              <ClickOutside
                onClickOutside={onClickOutside}
                clickOutsideEvents={['mouseup']}
              >
                <div className={styles.infolink}>
                  <Alink onClick={toggleCollapsed}>
                    <span>
                      <Icon isInline iconName="general/info" />
                    </span>
                    <span className={styles.text}>{linkText}</span>
                  </Alink>
                </div>
              </ClickOutside>
            )}
          </div>
        )}

        <ResponsiveImage
          data={{ image: imageDesktop, imageTablet, imagePhone }}
          containerClass={styles.promoImage}
          sizes={imageSizes}
        />
      </div>

      {promoBannerInfoText && (
        <SafeText
          className={classNames(styles.collapsedContent, {
            [styles.open]: isCollapsed,
          })}
          content={promoBannerInfoText}
        />
      )}
    </div>
  );

  function toggleCollapsed() {
    setCollapsed((isCollapsed) => !isCollapsed);
  }

  function onClickOutside() {
    if (!isCollapsed) return;

    toggleCollapsed();
  }
}
