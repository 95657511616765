import { useState, useEffect } from 'react';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import classNames from 'classnames';
import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import DesignApiService from 'mycs/shared/services/DesignApiService/DesignApiService';
import HeaderImage from 'mycs/shared/components/HeaderImage/HeaderImage';
import MediaPreview from 'mycs/shared/components/MediaPreview/MediaPreview';
import ProductModal from 'mycs/shared/components/ProductModal/ProductModal';
import styles from './Gallery.scss';
import type { CfGalleryItem, CfHeaderImage } from '@mycs/contentful';
import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';

interface ContentData {
  _id: string;
  _contentType: string;
  headerImage?: CfHeaderImage;
  items?: CfGalleryItem[];
}

interface Props {
  content: ContentData;
}

type ColumnType = (CfGalleryItem & {
  imageOrientation: string;
})[];

const Gallery: React.FC<Props> = ({ content }: Props) => {
  const [modalOpened, setModalOpened] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [itemsWithDesign, setItemsWithDesign] = useState<any[]>([]);
  const { hasPhoneDimensions, isTablet, isLandscape } = useDevice();
  const { locale, countryCode } = useLocale();

  const isSmallScreen = hasPhoneDimensions;
  const numberOfColumns = isSmallScreen
    ? isLandscape
      ? 3
      : 2
    : isTablet
    ? isLandscape
      ? 4
      : 3
    : 5;

  /**
   * Return orientation of image (portrait / landscape)
   */
  function getImageOrientation(item: any): string {
    const img = item.fullImage;
    return img.width >= img.height ? 'landscape' : 'portrait';
  }

  /**
   * Get gallery items as sorted collumns
   */
  function getColumns(items: CfGalleryItem[]): ColumnType[] {
    const cols = numberOfColumns;

    const columns: ColumnType[] = [];

    items
      .filter((item) => item.fullImage)
      .forEach((item, index) => {
        // Put the item into the right column
        const colIndex = index % cols;
        if (!columns[colIndex]) columns[colIndex] = [];

        columns[colIndex].push({
          ...item,
          // Put image orientation for mobile
          imageOrientation: getImageOrientation(item),
        });
      });

    return columns;
  }

  useEffect(() => {
    const loadRenderings = () => {
      let data = content && content.items;
      if (!data) return;
      data = uniq(compact(data));

      Promise.all(
        data
          .filter((item: any) => item.uuids)
          .map((item: any) => {
            return DesignApiService.getRendering(item.uuids, countryCode).then(
              (designs) => ({
                ...item,
                designs: designs.map((design) => ({
                  ...design,
                  url: RelativeUrlService.getConfiguratorUrl(
                    design.furniture_type,
                    design.uuid,
                    locale
                  ),
                })),
              }),
              () => {
                return item;
              }
            );
          })
      ).then(setItemsWithDesign);
    };

    loadRenderings();

    const handleResize = () => {
      // Trigger a re-render on resize
      loadRenderings();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [content, countryCode, locale]);

  const toggleModal = () => setModalOpened(!modalOpened);
  const setIndex = (index: number) => setActiveIndex(index);
  const displayItem = (item: any, items: object[]) => {
    const index = items.findIndex(({ _id }: any) => _id === item._id);
    setIndex(index);
    toggleModal();
  };

  const getItems = (): CfGalleryItem[] => {
    if (itemsWithDesign.length > 0) {
      return itemsWithDesign;
    }

    let data = content && content.items;
    if (!data) return [];
    data = uniq(compact(data));
    return data;
  };

  const items = getItems();
  const columns = getColumns(items);
  const imageSizes = '(max-width: 767px) 46vw, 17vw';

  return (
    <div>
      {content?.headerImage && (
        <HeaderImage
          heading={content.headerImage.heading}
          blurb={content.headerImage.blurb}
        />
      )}
      <ProductModal
        activeItem={items[activeIndex]}
        activeIndex={activeIndex}
        totalItems={items.length - 1}
        isOpen={modalOpened}
        onClose={toggleModal}
        onChange={setIndex}
        layout="legacy"
      />
      <div className="container">
        <div
          className={classNames(
            styles.gallery,
            styles[`column${columns.length}`]
          )}
        >
          {columns.map((column, columnIndex) => (
            <div className={styles.column} key={columnIndex}>
              {column.map((item, itemIndex: number) => (
                <div
                  className={styles.item}
                  onClick={() => displayItem(item, items)}
                  key={itemIndex}
                >
                  <MediaPreview
                    title={<span className={styles.title}>{item.title}</span>}
                    subtitle={
                      <span className={styles.subtitle}>
                        {item.shortDescription}
                      </span>
                    }
                    imageSrc={item.smallImage?.url}
                    imageAlt={item.smallImage?.description}
                    imageTitle={item.smallImage?.title}
                    imageWidth={item.smallImage?.width}
                    imageHeight={item.smallImage?.height}
                    imageSizes={imageSizes}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
