import { Dispatch, SetStateAction } from 'react';
import {
  FieldError,
  UseFormRegisterReturn,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

export enum FurnitureType {
  Shelf = 'shelf',
  Closet = 'closet',
  Wardrobe = 'wardrobe',
  Table = 'table',
  Couchtable = 'couchtable',
  Sydetable = 'sydetable',
  Chayr = 'chayr',
  Pryme = 'pryme',
  Samplebox = 'sample-box',
  Couch = 'couch',
  Pyllow = 'pyllow',
  Joyn = 'joyn',
  Cushion = 'cushion',
  Styng = 'styng',
}

export enum Filter {
  All = 'All',
  Shelves = 'Shelves',
  Sofas = 'Sofas',
}

export enum ReviewFormField {
  AuthorName = 'authorName',
  Description = 'description',
  FurnitureType = 'furnitureType',
  Photo = 'photo',
  Rating = 'rating',
  Terms = 'terms',
}

export interface ReviewFormData {
  authorName: string;
  description: string;
  furnitureType: FurnitureType | '';
  rating: number;
  photo?: File | null;
  terms?: boolean;
}

export interface ReviewFormFieldProps {
  register: UseFormRegisterReturn<ReviewFormField>;
  fieldLabel: string;
  error?: FieldError;
  tooltipInfo?: string;
}

export interface ReviewFormFurnitureTypeFieldProps
  extends ReviewFormFieldProps {
  selectedFurnitureType: FurnitureType | '';
  setSelectedFurnitureType: Dispatch<SetStateAction<FurnitureType>>;
  typeShelfLabel: string;
  typeSofaLabel: string;
}

export interface FurnitureTypeIconProps {
  isActive: boolean;
  label: string;
}

export interface AuthorNameFieldProps extends ReviewFormFieldProps {
  placeholder: string;
}

export interface PhotoFieldProps extends ReviewFormFieldProps {
  photoDescription: string;
  buttonLabel: string;
  fieldLabelRecommended: string;
  setValue: UseFormSetValue<ReviewFormData>;
  watch: UseFormWatch<ReviewFormData>;
}

export interface RatingFieldProps extends ReviewFormFieldProps {
  selectedStar: number;
  setSelectedStar: Dispatch<SetStateAction<number>>;
}
