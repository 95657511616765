import { useState } from 'react';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

import { CfTextBlock } from '@mycs/contentful';
import Button from 'mycs/shared/components/Button/Button';
import SafeText from 'mycs/shared/components/SafeText/SafeText';
import SmartImage from 'mycs/shared/components/SmartImage/SmartImage';
import { ResponsiveVideo } from 'mycs/shared/components/ResponsiveVideo/ResponsiveVideo';
import Anchor from 'mycs/shared/components/Anchor/Anchor';
import IframeLoader from 'mycs/shared/components/IframeLoader/IframeLoader';
import FaqAssemblyInstructions from 'mycs/landing-page/components/FaqAssemblyInstructions/FaqAssemblyInstructions';

import styles from './TextBlock.scss';

export type CustomCfTextBlock = Omit<CfTextBlock, 'htmlContent'> & {
  htmlContent: CfTextBlock['htmlContent'] | string;
};

export function LegacyTextBlock(
  props: Omit<CustomCfTextBlock, '_id' | '_contentType' | 'slug'>
) {
  return (
    <TextBlock
      content={{
        ...props,
        _id: 'legacySeoTextID',
        _contentType: 'pagepdpseo',
        slug: 'legacySeoText',
      }}
    />
  );
}

export default function TextBlock({ content }: { content: CustomCfTextBlock }) {
  const [collapsed, setCollapsed] = useState(content.readMore ? true : false);
  const rootStyles = {
    backgroundColor: content?.backgroundColor,
  };

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_: any, children: any[]) => {
        //filter empty paragraphs
        if (
          !children ||
          (Array.isArray(children) && children.every((child) => !child))
        ) {
          return;
        }
        const containsHTMLTags = (str: string) =>
          /<\/?[a-z][\s\S]*>/i.test(str);

        const extractIframeAttributes = (str: string) => {
          const attributes: { [key: string]: string } = {};
          const iframeMatch = /<iframe\s+([^>]*)>/i.exec(str);

          if (!iframeMatch) return attributes;

          const attributesString = iframeMatch[1];
          const attributeRegex = /(\w+)=['"]([^'"]*)['"]/g;

          let match;
          while ((match = attributeRegex.exec(attributesString))) {
            attributes[match[1]] = match[2];
          }

          return attributes;
        };

        const containsIframe = (str: string) =>
          /<iframe[\s\S]*?<\/iframe>/i.test(str);

        const parsedChildren = children.flatMap((child) => {
          if (typeof child !== 'string') {
            return child;
          }

          if (containsIframe(child)) {
            const iframeAttributes = extractIframeAttributes(child);
            return <IframeLoader key={child} {...iframeAttributes} />;
          }

          // If child contains HTML, wrap in SafeText
          if (containsHTMLTags(child)) {
            return <SafeText key={child} content={child} />;
          }

          // Else, process child like before
          return child.split('\n').map((part, index) => {
            if (index !== 0) {
              return (
                <>
                  <br />
                  {part}
                </>
              );
            }

            return part;
          });
        });

        return <p>{parsedChildren}</p>;
      },
      [INLINES.HYPERLINK]: (node: any, children: any) => {
        const uri = node.data.uri;
        if (uri.startsWith('mailto:')) {
          return (
            <a href={uri} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          );
        } else {
          // normal hyperlink handling
          return <a href={uri}>{children}</a>;
        }
      },
      // icons
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const { title, url } = node.data.target;
        return (
          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <SmartImage src={url} alt={title} title={title} height={30} />
          </div>
        );
      },

      [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
        const content = node.data.target;

        switch (content._contentType) {
          case 'assemblyInstructionLink':
            return <FaqAssemblyInstructions link={content} />;
          case 'video':
            return <ResponsiveVideo data={content} />;

          default:
            return null;
        }
      },
    },
  };

  // Handle Read more/less click
  const onClick = () => {
    setCollapsed((collapsed) => !collapsed);
  };

  // Render content
  const buttonText = collapsed ? 'read more' : 'read less';
  const containerClass = classNames(styles.container, {
    [styles.collapsed]: collapsed,
  });
  const htmlContent =
    typeof content.htmlContent === 'object' ? (
      <div className={containerClass}>
        {content?.title && <SafeText content={content.title} />}
        <div
          className={classNames(styles.htmlContentContainer, {
            [styles.twoColumnsContainer]: content?.htmlSecondColumn,
          })}
        >
          <div className={styles[`${content.layout}Container`]}>
            {documentToReactComponents(content.htmlContent, options)}
          </div>
          {content?.htmlSecondColumn && (
            <div>
              {documentToReactComponents(content.htmlSecondColumn, options)}
            </div>
          )}
        </div>
      </div>
    ) : (
      <SafeText className={containerClass} content={content.htmlContent} />
    );

  const btnClasses = [
    'isPrimaryCta',
    'isSecondaryCta',
    'isSecondaryLightCta',
    'isFlatPrimaryCta',
  ];

  const buttonClasses = content?.button?.buttonClasses || [];

  let buttonProps: { [key: string]: boolean } = {};

  btnClasses.forEach((possibleClass) => {
    buttonProps[possibleClass] = buttonClasses.includes(possibleClass);
  });

  // Render the button, potentially wrapped with an Anchor
  let actionElement = null;

  const isCentered = buttonClasses.includes('isCentered');
  const buttonComponent = (
    <Button
      href={content?.button?.url}
      text={content?.button?.text}
      {...buttonProps}
      target={content?.button?.target}
    />
  );

  if (content?.button?.isAnchor && content?.button.anchorTarget) {
    actionElement = (
      <div
        className={classNames(styles.container, styles.buttonWrapper, {
          [styles.centered]: isCentered,
        })}
      >
        <Anchor destination={content.button.anchorTarget}>
          {buttonComponent}
        </Anchor>
      </div>
    );
  } else if (content?.button) {
    actionElement = (
      <div
        className={classNames(styles.container, styles.buttonWrapper, {
          [styles.centered]: isCentered,
        })}
      >
        {buttonComponent}
      </div>
    );
  }

  const innerCode =
    content?.codeType === 'CSS' ? (
      <Helmet
        style={[
          {
            cssText: content.code,
          },
        ]}
      />
    ) : content?.codeType === 'JS' ? (
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: content.code,
          },
        ]}
      />
    ) : null;

  return (
    <div
      id={content.anchor}
      className={classNames(
        styles.container,
        styles[`${content.layout}Layout`],
        styles[`${content.mobileLayout}MobileLayout`],
        {
          [styles['largeLineSpacing']]: content?.lineSpacing === 'Large',
          [styles['mediumLineSpacing']]: content?.lineSpacing === 'Medium',
          [styles['smallLineSpacing']]: content?.lineSpacing === 'Small',
          [styles.centeredHeadings]: content?.centralizeTitles,
        }
      )}
      data-testid="text-block-container"
      style={rootStyles}
    >
      {htmlContent}

      {actionElement}

      {content.readMore && (
        <div className={styles.readMoreBtnWrapper}>
          <Button onClick={onClick} text={buttonText} isFlatPrimaryCta />
        </div>
      )}

      {content.code && innerCode}
    </div>
  );
}
