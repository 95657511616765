import { CfModelFinderCarousel } from '@mycs/contentful';
import { useDevice } from 'mycs/router/DeviceContext';

import Anchor from 'mycs/shared/components/Anchor/Anchor';
import Carousel from 'mycs/shared/components/Carousel/Carousel';
import SmartImage from 'mycs/shared/components/SmartImage/SmartImage';

import styles from './ModelFinderCarousel.scss';

export default function ModelFinderCarousel({
  content,
}: {
  content: CfModelFinderCarousel;
}) {
  const { hasPhoneDimensions } = useDevice();
  const modelFinderOffset = hasPhoneDimensions ? -50 : 0;
  const { models, title } = content;

  return (
    <>
      {title && <h3 className={styles.modelTitle}>{title}</h3>}
      <Carousel
        slidesToShow={models.length}
        arrows={false}
        dots={false}
        infinite={false}
      >
        {models.map((model, index) => (
          <Anchor
            key={index}
            destination={model.url ?? ''}
            offset={modelFinderOffset}
          >
            <div className={styles.modelContainer}>
              <SmartImage
                alt={model.title}
                title={model.title}
                src={model.image?.image.url ?? ''}
                className={styles.modelImage}
              />
              <div className={styles.furnitureTypeName}>{model.title}</div>
            </div>
          </Anchor>
        ))}
      </Carousel>
    </>
  );
}
