import { useMemo } from 'react';
import classNames from 'classnames';

import { getDesignsFromReduxState } from 'mycs/shared/state/slices/designsSlice';
import { useAppSelector } from 'mycs/shared/state/store';
import { useDesigns } from 'mycs/hooks/useDesigns';
import { useDesignsByCategory } from 'mycs/hooks/useDesignsByCategory';
import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import DesignApiService from 'mycs/shared/services/DesignApiService/DesignApiService';
import FilterableProducts from 'mycs/shared/components/FilterableProducts/FilterableProducts';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import Loader from 'mycs/shared/components/Loader/Loader';
import ProductsGrid from 'mycs/shared/components/ProductsGrid/ProductsGrid';
import type { CfProductGrid } from '@mycs/contentful';

import styles from './MainPageProductGrid.scss';
import buttonStyles from 'mycs/shared/components/Button/Button.scss';
import Button from 'mycs/shared/components/Button/Button';

export default function MainPageProductGrid({
  content: {
    gridTitle,
    gridBanners = [],
    productFilters,
    uuids,
    includeFilterOffset,
    hideFurnitureTypeFilter,
    hideTopMargin,
    category,
    primaryColorGroup,
  },
}: {
  content: CfProductGrid;
}) {
  const { countryCode, locale } = useLocale();
  const { hasPhoneDimensions } = useDevice();

  const shouldRenderDynamicSubcat = DesignApiService.shouldRenderCategoryData(
    { category },
    countryCode
  );

  const preloadedDesigns = useAppSelector((state) =>
    getDesignsFromReduxState(
      state,
      uuids,
      shouldRenderDynamicSubcat ? category : undefined,
      { primaryColorGroup }
    )
  );

  const { designs, isLoading, loadMore, hasMore } = useDesignsByCategory(
    preloadedDesigns,
    shouldRenderDynamicSubcat ? category : undefined,
    countryCode
  );

  const bannerDesignUuids = useMemo(() => {
    return DesignApiService.getGridBannersUUIDs(gridBanners);
  }, [gridBanners]);

  const bannerDesigns = useDesigns(bannerDesignUuids);

  if (!productFilters || shouldRenderDynamicSubcat) {
    return designs.length ? (
      <div
        id="products"
        className={classNames(styles.productsWrap, {
          [styles.hideTopMargin]: hideTopMargin,
        })}
      >
        {gridTitle && <h3 className={styles.title}>{gridTitle}</h3>}
        <ProductsGrid
          designs={designs}
          getFurnitureLabel={(furnitureType: string) =>
            `filters/subcat/${furnitureType}`
          }
          previewProps={{
            hasAddToCart: false,
            isPdpLink: hasPhoneDimensions,
            showImagesCarousel: !hasPhoneDimensions,
          }}
          hideFurnitureTypeFilter={hideFurnitureTypeFilter}
          gridBanners={gridBanners}
          bannerDesigns={bannerDesigns}
          noPagination={shouldRenderDynamicSubcat}
        />

        {shouldRenderDynamicSubcat && (
          <div className={styles.showMoreBtn}>
            {isLoading ? (
              <div className={styles.loaderContainer}>
                <Loader isSmall />
              </div>
            ) : hasMore ? (
              <Button
                onClick={loadMore}
                isPrimaryCta
                nativeProps={{ type: 'button', disabled: isLoading }}
              >
                <span className={buttonStyles.button__text}>
                  {I18nUtils.localize(locale, 'Load more')}
                </span>
              </Button>
            ) : null}
          </div>
        )}
      </div>
    ) : null;
  }

  let props: React.ComponentProps<typeof FilterableProducts> = {
    data: productFilters,
    filterUndockElementOffset: includeFilterOffset ? 100 : 0,
    isPdpLink: false,
  };

  if (uuids) {
    props.initialDesigns = uuids;
    props.gridBanners = gridBanners;
    props.bannerDesigns = bannerDesigns;
  }

  return <FilterableProducts {...props} />;
}
