import ProductPreviewPlaceholder from 'mycs/shared/components/Placeholder/ProductPreviewPlaceholder/ProductPreviewPlaceholder';
import PaginationPlaceholder from 'mycs/shared/components/Placeholder/PaginationPlaceholder/PaginationPlaceholder';

import styles from './ProductGridPlaceholder.scss';

export default function ProductGridPlaceholder() {
  const productsAmount = 12;
  return (
    <div className={styles.productsContainer}>
      <div className={styles.containerPlaceholder}>
        {Array.from({ length: productsAmount }).map((_, index) => (
          <div key={index} className={styles.productPlaceholder}>
            <ProductPreviewPlaceholder />
          </div>
        ))}
        <PaginationPlaceholder />
      </div>
    </div>
  );
}
