import { Fragment } from 'react';
import { CfVideo } from '@mycs/contentful';

import { useDevice } from 'mycs/router/DeviceContext';
import Video from 'mycs/shared/components/Video/Video';

export function ResponsiveVideo({ data }: { data: CfVideo }) {
  const { hasPhoneDimensions, hasTabletDimensions } = useDevice();
  const { buttonText, buttonLink, hideTitle, title, text } = data;

  const videoPoster = data.videoPoster?.url || '';
  const video = hasPhoneDimensions
    ? data.videoMobile?.url
    : hasTabletDimensions
    ? data.videoTablet?.url
    : data.video?.url || '';

  if (!video) {
    return <Fragment />;
  }

  return (
    <Video
      buttonText={buttonText}
      buttonLink={buttonLink}
      hideTitle={hideTitle}
      title={title}
      text={text}
      displayTitleAndTextOverlay={data?.displayTitleAndTextOverlay}
      videoPoster={videoPoster}
      video={video}
    />
  );
}
