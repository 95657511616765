import { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import xorWith from 'lodash/xorWith';

import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import Button from 'mycs/shared/components/Button/Button';
import CheckboxDialog from 'mycs/shared/components/CheckboxDialog/CheckboxDialog';
import ColorDialog from 'mycs/shared/components/ColorDialog/ColorDialog';
import DimensionsCheckboxDialog from 'mycs/shared/components/DimensionsCheckboxDialog/DimensionsCheckboxDialog';
import { Tab } from 'mycs/shared/components/Pillbox/Pillbox';
import RangeSlidersDialog from 'mycs/shared/components/RangeSlidersDialog/RangeSlidersDialog';
import Sticky from 'mycs/shared/components/Sticky/Sticky';
import styles from 'mycs/shared/components/FilterableProducts/FilterableProducts.scss';
import { CfProductFilter, CfProductFilterOption } from '@mycs/contentful';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import OptionsMenu from 'mycs/configurators/shared/components/OptionsMenu/OptionsMenu';

interface PricesDimensionsRanges {
  [key: string]: string;
}

interface Props {
  filters: CfProductFilter[] | null;
  tabs: Tab[];
  title: string;
  activeOptions: CfProductFilterOption[];
  appliedOptions: CfProductFilterOption[];
  isMobileFilterOpen: boolean;
  productsContainerRef: any;
  pricesDimensionsRanges: PricesDimensionsRanges | null;
  undockElementOffset?: number;
  toggleMobileFilter: (isOpen: boolean) => void;
  getAppliedOptions: (tabName: string) => CfProductFilterOption[];
  getActiveOptions: (tabName: string) => CfProductFilterOption[];
  getFilterOptions: (name: string) => CfProductFilterOption[];
  onUpdateActiveOptions: (
    options: CfProductFilterOption[],
    tabName: string
  ) => void;
  onResetActiveOptions: () => void;
  onSubmitActiveOptions: () => void;
}

export default function MobileFilters(props: Props) {
  const { locale } = useLocale();
  const {
    filters,
    tabs,
    title,
    activeOptions,
    appliedOptions,
    isMobileFilterOpen,
    productsContainerRef,
    pricesDimensionsRanges,
    undockElementOffset,
    toggleMobileFilter,
    getAppliedOptions,
    getActiveOptions,
    getFilterOptions,
    onUpdateActiveOptions,
    onSubmitActiveOptions,
    onResetActiveOptions,
  } = props;

  if (!filters) return <Fragment />;

  const stickyOffset = 48; // Navigation menu height in px

  const isConfirmDisabled = () => {
    const areOptionsSelectedApplied = isEmpty(
      xorWith(props.appliedOptions, props.activeOptions, isEqual)
    );
    return areOptionsSelectedApplied;
  };

  return (
    <div>
      <Sticky
        dockedClassName={styles.filterButtonStickyContainer}
        offset={stickyOffset}
        undockElement={productsContainerRef}
        undockElementOffset={undockElementOffset}
      >
        <div className={styles.filterButtonContainer}>
          <Button
            isSecondaryLightCta={appliedOptions.length === 0}
            isPrimaryCtaFallback={appliedOptions.length > 0}
            className={styles.filterButton}
            text={title}
            onClick={toggleMobileFilter as any}
          />
        </div>
      </Sticky>

      <OptionsMenu
        title={I18nUtils.localize(locale, 'Filters')}
        isOpen={isMobileFilterOpen}
        onChangeOpen={toggleMobileFilter}
      >
        <div className={styles.mobileFiltersContainer}>
          {tabs.map((tab: Tab, index: number) =>
            tab.id && getFilterOptions(tab.id) ? (
              <div key={index}>
                <div className={styles.filterTitle}>{tab.name}</div>

                {tab.id === 'price' && pricesDimensionsRanges && (
                  <RangeSlidersDialog
                    ranges={pricesDimensionsRanges}
                    options={getFilterOptions(tab.id)}
                    appliedOptions={getAppliedOptions(tab.id)}
                    activeOptions={getActiveOptions(tab.id)}
                    onUpdateActiveOptions={onUpdateActiveOptions}
                    type="price"
                    mobileLayout
                    tabName={tab.id}
                  />
                )}

                {tab.id === 'dimensions' &&
                  getFilterOptions(tab.id).filter((option) => option.isBoolean)
                    .length > 0 && (
                    <DimensionsCheckboxDialog
                      options={getFilterOptions(tab.id)}
                      appliedOptions={getAppliedOptions(tab.id)}
                      activeOptions={getActiveOptions(tab.id)}
                      onUpdateActiveOptions={onUpdateActiveOptions}
                      mobileLayout
                      tabName={tab.id}
                    />
                  )}

                {tab.id === 'dimensions' &&
                  pricesDimensionsRanges &&
                  getFilterOptions(tab.id).filter((option) => option.isBoolean)
                    .length <= 0 && (
                    <RangeSlidersDialog
                      ranges={pricesDimensionsRanges}
                      options={getFilterOptions(tab.id)}
                      appliedOptions={getAppliedOptions(tab.id)}
                      activeOptions={getActiveOptions(tab.id)}
                      onUpdateActiveOptions={onUpdateActiveOptions}
                      type="dimensions"
                      mobileLayout
                      tabName={tab.id}
                    />
                  )}

                {tab.id === 'color' && (
                  <ColorDialog
                    options={getFilterOptions(tab.id)}
                    appliedOptions={getAppliedOptions(tab.id)}
                    activeOptions={getActiveOptions(tab.id)}
                    onUpdateActiveOptions={onUpdateActiveOptions}
                    mobileLayout
                    tabName={tab.id}
                  />
                )}

                {['material', 'shape', 'model'].includes(tab.id) && (
                  <CheckboxDialog
                    options={getFilterOptions(tab.id)}
                    appliedOptions={getAppliedOptions(tab.id)}
                    activeOptions={getActiveOptions(tab.id)}
                    onUpdateActiveOptions={onUpdateActiveOptions}
                    mobileLayout
                    tabName={tab.id}
                  />
                )}
              </div>
            ) : (
              <Fragment key={index} />
            )
          )}
        </div>

        {activeOptions.length > 0 && (
          <div className={styles.mobileCancelButtonContainer}>
            <Button
              isFlatSecondaryCta
              text={I18nUtils.localize(locale, 'Clear selection')}
              onClick={onResetActiveOptions}
            />
          </div>
        )}

        <div className={styles.mobileConfirmButtonContainer}>
          <Button
            isPrimaryCta
            isFullWidth
            isDisabled={isConfirmDisabled()}
            className={styles.mobileConfirmButton}
            text={I18nUtils.localize(locale, 'Show results')}
            onClick={onSubmitActiveOptions}
          />
        </div>
      </OptionsMenu>
    </div>
  );
}
