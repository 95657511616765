import classNames from 'classnames';

import Button from 'mycs/shared/components/Button/Button';
import Scrollable from 'mycs/shared/components/Scrollable/Scrollable';

import styles from './Pillbox.scss';

export interface Tab {
  id?: string;
  name: string;
  trackingSlug?: string;
  inactive?: boolean;
}

type Props = {
  tabs: Tab[];
  activeTab: Tab | undefined;
  onTabChange: (c: Tab) => void;
  outlined?: boolean;
  scrollableProps?: {
    arrows?: boolean;
    small?: boolean;
    centered?: boolean;
    wrap?: boolean;
  };
  buttonStyle?: string;
  hideBorderBottom?: boolean;
  className?: string;
  configuratorLayout?: boolean;
};

export default function Pillbox({
  activeTab,
  tabs,
  outlined,
  scrollableProps,
  buttonStyle,
  hideBorderBottom,
  className,
  configuratorLayout,
  onTabChange,
}: Props) {
  return (
    <div
      className={classNames(styles.container, className, {
        [styles.outlined]: outlined,
        [styles.containerBorderBottom]: !hideBorderBottom,
        [styles.configuratorLayout]: configuratorLayout,
      })}
    >
      <Scrollable {...scrollableProps}>
        {tabs.map((tab, i) => (
          <Button
            trackLabel={tab.trackingSlug}
            key={i}
            onClick={() => onTabChange(tab)}
            className={classNames(
              styles.tab,
              {
                [styles.active]: activeTab && activeTab.name === tab.name,
              },
              buttonStyle
            )}
            text={tab.name}
            isDisabled={tab.inactive}
          />
        ))}
      </Scrollable>
    </div>
  );
}
