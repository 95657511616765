import { Component } from 'react';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import xorWith from 'lodash/xorWith';
import Button from 'mycs/shared/components/Button/Button';
import Checkbox from 'mycs/shared/components/Checkbox/Checkbox';
import styles from './CheckboxDialog.scss';
import { CfProductFilterOption } from '@mycs/contentful';

interface Props {
  options: CfProductFilterOption[];
  appliedOptions?: CfProductFilterOption[];
  activeOptions?: CfProductFilterOption[];
  onUpdateActiveOptions?: (
    options: CfProductFilterOption[],
    tabName: string
  ) => void;
  onResetActiveOptions?: (options: CfProductFilterOption[]) => void;
  onSubmitActiveOptions?: () => void;
  onCancel?: () => void;
  mobileLayout?: boolean;
  tabName: string;
}

interface State {
  currentOptions: CfProductFilterOption[];
}

export default class CheckboxDialog extends Component<Props, State> {
  static defaultProps = {
    mobileLayout: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      currentOptions: [],
    };
  }

  onChange = (currentOption: CfProductFilterOption) => {
    const currentOptions = cloneDeep(this.state.currentOptions) || [];
    const index = currentOptions.findIndex(
      (option) => option.queryParamValue === currentOption.queryParamValue
    );
    // If the option is already selected, toggle it
    if (index < 0) {
      currentOptions.push(currentOption);
    } else {
      currentOptions.splice(index, 1);
    }
    this.setState({ currentOptions }, () => {
      if (this.props.onUpdateActiveOptions) {
        this.props.onUpdateActiveOptions([currentOption], this.props.tabName);
      }
    });
  };

  onConfirm = () => {
    if (this.props.onSubmitActiveOptions) {
      this.props.onSubmitActiveOptions();
    }
  };

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onReset = () => {
    if (this.props.onResetActiveOptions) {
      this.props.onResetActiveOptions(this.state.currentOptions);
    }
    this.setState({ currentOptions: [] });
  };

  isChecked = (option: CfProductFilterOption) => {
    return Boolean(
      this.state.currentOptions.find(
        (currentOption) =>
          currentOption.queryParamValue === option.queryParamValue
      )
    );
  };

  isConfirmDisabled = () => {
    const areOptionsSelectedApplied = isEmpty(
      xorWith(this.props.appliedOptions, this.state.currentOptions, isEqual)
    );
    return areOptionsSelectedApplied;
  };

  isResetDisabled = () => {
    const { currentOptions } = this.state;
    return !currentOptions || currentOptions.length === 0;
  };

  initActiveOption = () => {
    if (!this.props.appliedOptions) return;
    if (this.props.appliedOptions.length > 0) {
      this.setState({ currentOptions: this.props.appliedOptions });
    }
  };

  initActiveOptionMobile = () => {
    if (!this.props.activeOptions) return;
    this.setState({ currentOptions: this.props.activeOptions });
  };

  componentDidMount() {
    if (this.props.mobileLayout) {
      this.initActiveOptionMobile();
    } else {
      this.initActiveOption();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.mobileLayout) {
      if (this.props.activeOptions !== prevProps.activeOptions) {
        this.initActiveOptionMobile();
      }
    } else {
      if (
        this.props.options !== prevProps.options &&
        this.props.appliedOptions !== prevProps.appliedOptions
      ) {
        // Reset current options when switching tabs
        this.setState({ currentOptions: [] }, () => {
          this.initActiveOption();
        });
      }
    }
  }

  render(): JSX.Element {
    const { options, mobileLayout } = this.props;

    return (
      <div
        className={classNames(styles.container, {
          [styles.mobileLayout]: mobileLayout,
        })}
      >
        <div className={styles.closeButton}>
          <Button onClick={this.onCancel} iconName="general/close.svg" />
        </div>

        <div className={styles.checkboxOptionsContainer}>
          {options.map((checkboxOption, index) => (
            <div className={styles.checkboxOptionContainer} key={index}>
              <Checkbox
                modernLayout
                isBig
                label={checkboxOption.label}
                nativeProps={{
                  id: checkboxOption.queryParamValue,
                  name: checkboxOption.queryParamValue,
                }}
                checked={this.isChecked(checkboxOption)}
                onChange={() => this.onChange(checkboxOption)}
              />
            </div>
          ))}
        </div>

        <div className={styles.buttonsContainer}>
          {options.length > 3 && (
            <div className={styles.resetButton}>
              <Button
                onClick={this.onReset}
                text="Delete"
                isFlatSecondaryCta
                isDisabled={this.isResetDisabled()}
              />
            </div>
          )}

          <div className={styles.confirmButton}>
            <Button
              onClick={this.onConfirm}
              text="Confirm"
              isPrimaryCta
              isDisabled={this.isConfirmDisabled()}
            />
          </div>
        </div>
      </div>
    );
  }
}
