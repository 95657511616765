import { Component } from 'react';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import xorWith from 'lodash/xorWith';
import Button from 'mycs/shared/components/Button/Button';
import RangeSlider from 'mycs/shared/components/RangeSlider/RangeSlider';
import styles from './RangeSlidersDialog.scss';
import { CfProductFilterOption } from '@mycs/contentful';

interface SliderName {
  name: string;
  label: string;
}

interface Ranges {
  [key: string]: string;
}

interface Props {
  ranges: Ranges;
  options: CfProductFilterOption[];
  appliedOptions?: CfProductFilterOption[];
  activeOptions?: CfProductFilterOption[];
  onUpdateActiveOptions?: (
    options: CfProductFilterOption[],
    tabName: string
  ) => void;
  onResetActiveOptions?: (options: CfProductFilterOption[]) => void;
  onSubmitActiveOptions?: () => void;
  onCancel?: () => void;
  type: string; // "price" or "dimensions"
  mobileLayout?: boolean;
  tabName: string;
}

interface State {
  currentOptions: CfProductFilterOption[];
}

export default class RangeSlidersDialog extends Component<Props, State> {
  static defaultProps = {
    mobileLayout: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      currentOptions: [],
    };
  }

  onChange = (name: string, value: number[]) => {
    const { options, tabName } = this.props;
    if (options.length === 0) return;

    const currentOptions = cloneDeep(this.state.currentOptions) || [];
    const currentOptionMin = options.find(
      (option) => option.queryParam === `${name}_min`
    );
    const currentOptionMax = options.find(
      (option) => option.queryParam === `${name}_max`
    );

    if (currentOptionMin) {
      currentOptionMin.queryParamValue = value[0].toString();
      const indexMin = currentOptions.findIndex(
        (option) => option.queryParam === currentOptionMin!.queryParam
      );
      // If the option exists, remove it
      if (indexMin >= 0) {
        currentOptions.splice(indexMin, 1);
      }
      currentOptions.push(currentOptionMin);
    }

    if (currentOptionMax) {
      currentOptionMax.queryParamValue = value[1].toString();
      const indexMax = currentOptions.findIndex(
        (option) => option.queryParam === currentOptionMax!.queryParam
      );
      // If the option exists, remove it
      if (indexMax >= 0) {
        currentOptions.splice(indexMax, 1);
      }
      currentOptions.push(currentOptionMax);
    }

    this.setState({ currentOptions }, () => {
      if (this.props.onUpdateActiveOptions) {
        this.props.onUpdateActiveOptions(currentOptions, tabName);
      }
    });
  };

  onConfirm = () => {
    if (this.props.onSubmitActiveOptions) {
      this.props.onSubmitActiveOptions();
    }
  };

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onReset = () => {
    if (this.props.onResetActiveOptions) {
      this.props.onResetActiveOptions(this.state.currentOptions);
    }
    this.setState({ currentOptions: [] });
  };

  /**
   * Get minimum range value by name
   */
  getMinRange(name: string) {
    return Number(this.props.ranges[`${name}_min`]);
  }

  /**
   * Get maximum range value by name
   */
  getMaxRange(name: string) {
    return Number(this.props.ranges[`${name}_max`]);
  }

  /**
   * Get initial range by name
   */
  getInitialRange(name: string) {
    let minValue = this.getMinRange(name);
    let maxValue = this.getMaxRange(name);

    const { currentOptions } = this.state;
    if (currentOptions && currentOptions.length > 0) {
      const options = currentOptions.filter((option) =>
        option.queryParam.includes(name)
      );
      const minOptionIndex = options.findIndex(
        (option) => option.queryParam === `${name}_min`
      );
      const maxOptionIndex = options.findIndex(
        (option) => option.queryParam === `${name}_max`
      );

      if (minOptionIndex >= 0) {
        minValue = Number(options[minOptionIndex].queryParamValue);
      }
      if (maxOptionIndex >= 0) {
        maxValue = Number(options[maxOptionIndex].queryParamValue);
      }
    }

    return [minValue, maxValue];
  }

  /**
   * Group range options and return unique slider names
   */
  getSliderNames() {
    const names: SliderName[] = [];
    this.props.options.forEach((option) => {
      const groupName = option.queryParam.split('_')[0];
      const groupIndex = names.findIndex(
        (sliderName: SliderName) => sliderName.name === groupName
      );
      if (groupIndex < 0) {
        names.push({
          name: groupName,
          label: option.label || '',
        });
      }
    });
    return names;
  }

  /**
   * Check if the selected options are already applied
   */
  areOptionsSelectedApplied() {
    return isEmpty(
      xorWith(this.props.appliedOptions, this.state.currentOptions, isEqual)
    );
  }

  /**
   * Check if the initial ranges are selected
   */
  areDefaultOptionsSelected() {
    for (let i = 0; i < this.getSliderNames().length; i++) {
      const sliderName = this.getSliderNames()[i];
      const currentOptions = this.state.currentOptions.filter((option) =>
        option.queryParam.includes(sliderName.name)
      );
      // Sorted array containing the range values of the current options
      const currentOptionsValues = currentOptions
        .map((option) => Number(option.queryParamValue))
        .sort((a, b) => a - b);
      const defaultOptionsValues = [
        this.getMinRange(sliderName.name),
        this.getMaxRange(sliderName.name),
      ];
      const isInitialRangeSelected =
        isEqual(currentOptionsValues, defaultOptionsValues) ||
        currentOptions.length === 0;
      if (!isInitialRangeSelected) {
        return false;
      }
    }
    return true;
  }

  /**
   * Check if the confirm button should be disabled or not
   */
  isConfirmDisabled() {
    return this.props.appliedOptions && this.props.appliedOptions.length > 0
      ? this.areOptionsSelectedApplied()
      : this.areDefaultOptionsSelected();
  }

  /**
   * Check if the reset button should be disabled or not
   */
  isResetDisabled = () => {
    return this.areDefaultOptionsSelected();
  };

  initActiveOption = () => {
    if (!this.props.appliedOptions) return;
    if (this.props.appliedOptions.length > 0) {
      this.setState({ currentOptions: this.props.appliedOptions });
    }
  };

  initActiveOptionMobile = () => {
    if (!this.props.activeOptions) return;
    this.setState({ currentOptions: this.props.activeOptions });
  };

  componentDidMount() {
    if (this.props.mobileLayout) {
      this.initActiveOptionMobile();
    } else {
      this.initActiveOption();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.mobileLayout) {
      if (
        this.props.type !== prevProps.type &&
        this.props.activeOptions !== prevProps.activeOptions
      ) {
        this.initActiveOptionMobile();
      }
    } else {
      if (
        this.props.type !== prevProps.type &&
        this.props.appliedOptions !== prevProps.appliedOptions
      ) {
        // Reset current options when switching tabs
        this.setState({ currentOptions: [] }, () => {
          this.initActiveOption();
        });
      }
    }
  }

  render(): JSX.Element {
    const { type, mobileLayout } = this.props;
    const columnsLayout = this.getSliderNames().length > 1;
    const rowsLayout = this.getSliderNames().length > 2;

    return (
      <div
        className={classNames(
          styles.container,
          { [styles.columnsLayout]: columnsLayout },
          { [styles.rowsLayout]: rowsLayout },
          { [styles.mobileLayout]: mobileLayout }
        )}
      >
        <div className={styles.closeButton}>
          <Button onClick={this.onCancel} iconName="general/close.svg" />
        </div>

        <div className={styles.slidersContainer}>
          {this.getSliderNames().map((sliderName, index) => (
            <div key={index} className={styles.sliderContainer}>
              <RangeSlider
                minValue={this.getMinRange(sliderName.name)}
                maxValue={this.getMaxRange(sliderName.name)}
                initialRange={this.getInitialRange(sliderName.name)}
                onChange={this.onChange}
                type={type}
                name={sliderName.name}
                label={sliderName.label}
                multipleSliders={this.getSliderNames().length > 1}
              />
            </div>
          ))}
        </div>

        <div className={styles.buttonsContainer}>
          <div className={styles.resetButton}>
            <Button
              onClick={this.onReset}
              text="Delete"
              isFlatSecondaryCta
              isDisabled={this.isResetDisabled()}
            />
          </div>

          <div className={styles.confirmButton}>
            <Button
              onClick={this.onConfirm}
              text="Confirm"
              isPrimaryCta
              isDisabled={this.isConfirmDisabled()}
            />
          </div>
        </div>
      </div>
    );
  }
}
