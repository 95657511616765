import { CfFaqAssemblyInstructionLink } from '@mycs/contentful';
import Alink from 'mycs/shared/components/Alink/Alink';
import Icon from 'mycs/shared/components/Icon/Icon';

import styles from './FaqAssemblyInstructions.scss';

type Props = {
  link: CfFaqAssemblyInstructionLink;
};

export default function FaqAssemblyInstructions({ link }: Props) {
  return (
    <Alink
      className={styles.link}
      href={link?.pdf?.url}
      rel="noopener"
      target="_blank"
      title={link?.pdf?.description}
    >
      <Icon iconPath={link?.icon?.url} className={styles.icon} />
      {link.title}
    </Alink>
  );
}
