import styles from './PaginationPlaceholder.scss';

export default function PaginationPlaceholder() {
  return (
    <div className={styles.container}>
      <div className={styles.pageButton}></div>
      <div className={styles.pageButton}></div>
      <div className={styles.pageButton}></div>
      <div className={styles.pageButtonMobile}></div>
    </div>
  );
}
