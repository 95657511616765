import { CfComponent, CfPageWithLayout } from '@mycs/contentful';
import BreadcrumbsLP from 'mycs/landing-page/components/BreadcrumbsLP/BreadcrumbsLP';
import CatPreviews from 'mycs/landing-page/components/CatPreviews/CatPreviews';
import CountdownRibbon from 'mycs/shared/components/CountdownRibbon/CountdownRibbon';
import DiscountExplainer from '../components/DiscountExplainer/DiscountExplainer';
import FurnitureTypeCard from 'mycs/shared/components/FurnitureTypeCard/FurnitureTypeCard';
import HeroBanner from 'mycs/shared/components/HeroBanner/HeroBanner';
import HeroBlock from 'mycs/shared/components/HeroBlock/HeroBlock';
import Jalousie from 'mycs/shared/components/Jalousie/Jalousie';
import MainPageProductGrid from 'mycs/landing-page/components/MainPageProductGrid/MainPageProductGrid';
import NewsletterFormBanner from 'mycs/shared/components/NewsletterFormBanner/NewsletterFormBanner';
import ProductFeatures from 'mycs/shared/components/ProductFeatures/ProductFeatures';
import AnchorLinkHeader from 'mycs/shared/components/AnchorLinkHeader/AnchorLinkHeader';
import MaterialCategories from 'mycs/shared/components/MaterialCategories/MaterialCategories';
import ButtonLink from 'mycs/shared/components/ButtonLink/ButtonLink';
import ReviewsBlock from 'mycs/shared/components/ReviewsBlock/ReviewsBlock';
import SellingPoints from 'mycs/shared/components/SellingPoints/SellingPoints';
import ShowroomUsps from 'mycs/shared/components/ShowroomUsps/ShowroomUsps';
import SimpleNav from 'mycs/shared/components/SimpleNav/SimpleNav';
import TextBlock from 'mycs/shared/components/TextBlock/TextBlock';
import Video from 'mycs/shared/components/Video/Video';
import ModelFinderCarousel from 'mycs/shared/components/ModelFinderCarousel/ModelFinderCarousel';
import TopDesignsCarousel from 'mycs/shared/components/TopDesignsCarousel/TopDesignsCarousel';
import ProductsDisplay from 'mycs/shared/components/ProductsDisplay/ProductsDisplay';
import QACategory from 'mycs/shared/components/QACategory/QACategory';
import PromoBanner from 'mycs/shared/components/PromoBanner/PromoBanner';
import CustomerTestimonials from 'mycs/shared/components/CustomerTestimonials/CustomerTestimonials';

/**
 * A map from _contentType to the matching component that will render a given content
 */
const contentToComponentMap: Record<
  string,
  (...props: any) => JSX.Element | null
> = {
  b2bPage: CountdownRibbon,
  discountsExplainer: DiscountExplainer,
  furnitureFeatureList: ProductFeatures,
  furnitureTypeCard: FurnitureTypeCard,
  heroBlock: HeroBlock,
  infoBannerCarousel: ModelFinderCarousel,
  interiorPage: ReviewsBlock,
  jalousie: Jalousie,
  pagepdpseo: TextBlock,
  responsiveVideo: SellingPoints,
  video: Video,
  serviceSpec: NewsletterFormBanner,
  serviceSpecsGroup: ShowroomUsps,
  wizardFurnitureColor: MainPageProductGrid,
  wizardFurnitureType: CatPreviews,
  wizardFurnitureTypes: SimpleNav,
  wizardFurnitureSize: MaterialCategories,
  wizardPage: HeroBanner,
  wizardStep: BreadcrumbsLP,
  buttonLink: ButtonLink,
  printAdPage: AnchorLinkHeader,
  products: TopDesignsCarousel,
  blackFridayConfig: ProductsDisplay,
  faqCategory: QACategory,
  ribbon: PromoBanner,
  productComparisonCard: CustomerTestimonials,
};

type Props = {
  content: CfComponent;
};

/**
 * RenderContent will analyse a given content and will find which React Component
 * should render it according to its _contentType value
 * @param content a Contentful content data to be rendered
 * @returns the JSX rendered for the given content
 */
export function RenderContent({ content }: Props) {
  const Component = contentToComponentMap[content._contentType];

  if (!Component) {
    // if the content type is not supported yet we cannot render it
    return null;
  }

  return <Component content={content} />;
}

/**
 * In order to keep backwards compatibility with Contentful pages that were not
 * updated to use the new content rendered, we should check if the legacy content
 * should be rendered
 *
 * @param page a Contentful page that supports the new content renderer
 * @param contentType the content type that will be analysed
 * @returns if the contentType should be rendered using the legacy content render from the page
 */
export function shouldRenderLegacyContent(
  page: CfPageWithLayout,
  contentType: string
) {
  const Component = contentToComponentMap[contentType];

  /**
   * legacy content should be rendered if there is no matching component
   * or if the page has no content
   */
  if (!Component || !page.content) {
    return true;
  }

  const contentAlreadyMigrated = page.content.find(
    ({ _contentType }) => _contentType === contentType
  );

  // legacy content should not be rendered if the content was already migrated
  return !contentAlreadyMigrated;
}

export const shouldRenderLegacyHeroBanner = (data: CfPageWithLayout) =>
  shouldRenderLegacyContent(data, 'wizardPage');

export const shouldRenderLegacyNewsletterFormBanner = (
  data: CfPageWithLayout
) => shouldRenderLegacyContent(data, 'serviceSpec');

export const shouldRenderLegacySeoText = (data: CfPageWithLayout) =>
  shouldRenderLegacyContent(data, 'pagepdpseo');
