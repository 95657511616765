import { PureComponent } from 'react';
import { LazyLoadTypes } from 'react-slick';

import { CfHeaderImage } from '@mycs/contentful';
import Carousel from 'mycs/shared/components/Carousel/Carousel';
import HeaderImage from 'mycs/shared/components/HeaderImage/HeaderImage';

import styles from './HeaderImageCarousel.scss';
import classNames from 'classnames';

type Props = {
  images: CfHeaderImage[];
  className?: string;
  isCentered?: boolean;
};

type State = {
  autoplay: boolean;
  lazyLoad: LazyLoadTypes;
};

export default class HeaderImageCarousel extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      autoplay: true,
      lazyLoad: 'ondemand',
    };
  }

  /**
   * Toggle the autoplay on click on the header image
   */
  toggleAutoplay = () => {
    this.setState({ autoplay: !this.state.autoplay });
  };

  /**
   * Re-activate the autoplay when changing slides
   */
  activateAutoplay = () => {
    if (this.state.autoplay) return;
    this.setState({ autoplay: true });
  };

  /**
   * Render a header image by index
   */
  renderHeaderImage(item: CfHeaderImage): React.ReactNode {
    return (
      <HeaderImage
        image={item.image}
        imageTablet={item.imageTablet}
        imagePhone={item.imagePhone}
        lowresUrl={item.imageBase64}
        anchors={item.anchors}
        heading={item.heading}
        blurb={item.blurb}
        button={item.button}
        carousel
        textColour={item.textColour}
        gradientOverlay={item.gradientOverlay}
      />
    );
  }

  render() {
    const { images, className, isCentered = false } = this.props;
    const { autoplay, lazyLoad } = this.state;

    return (
      <div
        className={classNames(styles.container, className, {
          [styles.centerAlignment]: isCentered,
          ['container']: isCentered,
        })}
      >
        <div className={styles.preload}>
          {this.renderHeaderImage(images[0])}
        </div>

        <div className={styles.carouselWrapper}>
          <div className={classNames({ [styles.carousel]: !isCentered })}>
            <Carousel
              beforeChange={this.activateAutoplay}
              lazyLoad={lazyLoad}
              autoplay={autoplay}
              autoplaySpeed={5000}
              useCSS
              arrows
              dots
              fade
              fullWidth
              swipe
            >
              {images.map((item, index) => (
                <div key={index} onClick={this.toggleAutoplay}>
                  {this.renderHeaderImage(item)}
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}
