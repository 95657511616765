/**
 * Convert ISO string to 'dd.mm.yyyy'.
 * @param {string} isoString
 * @returns {string}
 */
export function convertISOToMMDDYYYY(isoString: string) {
  const date = new Date(isoString);
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  return day + '.' + month + '.' + year;
}

/**
 * Add weeks.
 * @param {string} isoString
 * @returns {string} ISO string
 */
export function addWeeks(isoString: string, numWeeks: number) {
  const date = new Date(isoString);
  date.setDate(date.getDate() + numWeeks * 7);
  return date.toISOString();
}

/**
 * Is past date.
 * @param {string} isoString
 * @returns {boolean}
 */
export function isPastDate(isoString: string) {
  const date = new Date(isoString);
  const now = new Date();
  return date < now;
}

export function daysToWeeks(days: number | string) {
  return typeof days === 'number' ? Math.ceil(days / 7) : days;
}

export function formatReviewDate(dateString: string, locale: string): string {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString(locale, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  return locale.includes('de') ? formattedDate.replace('.', '') : formattedDate;
}
