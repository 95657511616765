import { CfSubcategory } from '@mycs/contentful';
import MediaPreview from 'mycs/shared/components/MediaPreview/MediaPreview';

import styles from './QuickLinks.scss';

interface Props {
  items: CfSubcategory[];
}

export default function QuickLinks({ items }: Props) {
  return (
    <div className={styles.container}>
      {items.map((item: CfSubcategory, index: number): JSX.Element => {
        const { image } = item;
        // Sizes for SmartImage
        const imageSizes = '(max-width: 767px) 100vw, 50vw';
        return (
          <div key={index} className={styles.preview}>
            <MediaPreview
              title={<span className={styles.title}>{item.title}</span>}
              reference={item.url}
              imageSrc={image?.url}
              imageAlt={image?.description}
              imageTitle={image?.title}
              imageSizes={imageSizes}
              imageWidth={image?.width}
              imageHeight={image?.height}
            />
          </div>
        );
      })}
    </div>
  );
}
