import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import { useLocale } from 'mycs/shared/state/LocaleContext';

import styles from './ProductFiltersPlaceholder.scss';

export default function ProductFiltersPlaceholder() {
  const { locale } = useLocale();

  return (
    <div className={styles.filtersContainer}>
      <div className={styles.filterTabsContainer}>
        <div className={styles.filterTabsTitle}>
          {I18nUtils.localize(locale, 'Filter')}
        </div>
        <div className={styles.pillboxPlaceholder}></div>
        <div className={styles.pillboxPlaceholder}></div>
        <div className={styles.pillboxPlaceholder}></div>
        <div className={styles.pillboxPlaceholder}></div>
      </div>
      <div className={styles.filtersBtnPlaceholder}></div>
    </div>
  );
}
