import Button from 'mycs/shared/components/Button/Button';
import { CfButtonLink } from '@mycs/contentful';
import HelpStore from 'mycs/shared/stores/HelpStore/HelpStore';
import Anchor from 'mycs/shared/components/Anchor/Anchor';
import styles from './ButtonLink.scss';
interface ButtonLinkProps {
  content: CfButtonLink;
  onClick?: () => void;
  onClose?: () => void;
  className?: string;
}

export default function ButtonLink({
  content,
  onClick,
  onClose,
}: ButtonLinkProps) {
  const handleClick = () => {
    if (content?.contactCustomerService) {
      HelpStore.toggle();
      HelpStore.show();
    }

    if (onClick) {
      onClick();
    }

    if (onClose) {
      onClose();
    }
  };

  const classes =
    content?.buttonClasses?.reduce<Record<string, boolean>>((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {}) || {};

  const mobileClasses =
    content?.mobileButtonClasses?.reduce<Record<string, boolean>>(
      (acc, curr) => {
        acc[`${curr}Mobile`] = true;
        return acc;
      },
      {}
    ) || {};

  if (content?.isAnchor && content?.anchorTarget) {
    return (
      <Anchor
        className={styles.anchor}
        destination={content?.anchorTarget}
        text={content?.text}
        iconClassName={styles.anchorIcon}
        iconName={content?.iconName}
      />
    );
  }
  return (
    <Button
      href={content?.contactCustomerService ? '' : content?.url}
      onClick={handleClick}
      text={content?.text}
      {...classes}
      {...mobileClasses}
    />
  );
}
