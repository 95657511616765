import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { CfJalousie, CfJalousieItem } from '@mycs/contentful';
import { removeHashWithoutReload } from 'mycs/router/history';
import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import { useTrackEvent } from '../TrackEvent/useTrackEvent';
import Button from 'mycs/shared/components/Button/Button';
import ContentfulUtils from 'mycs/shared/utilities/ContentfulUtils/ContentfulUtils';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import Icon from 'mycs/shared/components/Icon/Icon';
import SafeText from 'mycs/shared/components/SafeText/SafeText';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';

import styles from './Jalousie.scss';

// initial borders
const start = [100 / 3, (2 * 100) / 3];

// arbitrary values for default states
const defaultIndex = 555;
const defaultHoverIndex = 777;

/**
 * Return section borders for desktop
 */
function getDesktopBorders(index: number, hoverIndex: number | null): number[] {
  const sm = 5.5;
  const md = 8.5;
  const diffIndex = index !== hoverIndex;
  const left =
    (hoverIndex === 0 && diffIndex) || (hoverIndex === 1 && index === 0)
      ? md
      : sm;
  const right =
    (hoverIndex === 2 && diffIndex) || (hoverIndex === 1 && index === 2)
      ? md
      : sm;

  const map: { [index: number]: number[] } = {
    0: [100 - left - right, 100 - right],
    1: [left, 100 - right],
    2: [left, left + right],
    [defaultIndex]: start,
  };

  return map[index];
}

/**
 * Return section borders for mobile
 */
function getMobileBorders(index: number): number[] {
  const map: { [index: number]: number[] } = {
    0: [100, 100],
    1: [0, 100],
    2: [0, 0],
    [defaultIndex]: start,
  };

  return map[index];
}

type Props = {
  content: CfJalousie;
};

type State = {
  selectedIndex: number;
  bordersDesktop: number[];
  bordersMobile: number[];
  hoverIndex?: number;
};

export default function Jalousie({ content: { title, items = [] } }: Props) {
  const { locale } = useLocale();
  const { hash } = useLocation();
  const { hasPhoneDimensions } = useDevice();
  const trackEvent = useTrackEvent();
  const [state, setState] = useState<State>({
    selectedIndex: defaultIndex,
    bordersDesktop: start,
    bordersMobile: start,
    hoverIndex: undefined,
  });

  const noneOpen = state.selectedIndex === defaultIndex;
  const selectedIndexByHash = hash
    ? parseInt(hash.replace(/\D/g, ''), 10)
    : NaN;

  const openSection = (index: number) => {
    setState({
      ...state,
      hoverIndex: defaultHoverIndex,
      selectedIndex: index,
      bordersDesktop: getDesktopBorders(index, defaultHoverIndex),
      bordersMobile: getMobileBorders(index),
    });

    removeHashWithoutReload();
  };

  const updateIndex = () => {
    const index = selectedIndexByHash;

    if (!isNaN(index)) {
      openSection(index);
    }
  };

  const hover = (hoverIndex: number) => {
    setState({
      ...state,
      hoverIndex,
      bordersDesktop: getDesktopBorders(state.selectedIndex, hoverIndex),
      bordersMobile: getMobileBorders(state.selectedIndex),
    });
  };

  useEffect(() => {
    updateIndex();
  }, []);

  return (
    <div>
      {title && <h4 className={styles.heading}>{title}</h4>}

      <section
        className={classNames('contentContainer', styles.jalousie, {
          [styles.jalousieMobile]: hasPhoneDimensions,
          [styles.jalousieDesktop]: !hasPhoneDimensions,
        })}
        onMouseOut={() => hover(defaultHoverIndex)}
      >
        {items.map(
          hasPhoneDimensions ? renderSectionMobile : renderSectionDesktop
        )}
      </section>
    </div>
  );

  function renderSectionDesktop(
    data: CfJalousieItem,
    index: number
  ): React.ReactNode {
    const isOpen = state.selectedIndex === index;
    const isHovered = index === state.hoverIndex;

    const style = {
      backgroundImage: getImageBackground(data),
      ...getDesktopStyle(index),
    };

    const stylesInitial = classNames({
      [styles.hide]: !noneOpen,
      [styles.initial]: noneOpen,
      [styles.initialHover]: isHovered && noneOpen,
    });

    const stylesNumber = classNames(styles.numberBottom, {
      [styles.hide]: noneOpen,
      [styles.numberOpened]: isOpen,
    });

    const stylesContent = classNames(styles.content, {
      [styles.contentDark]: data.darkText,
      [styles.contentRight]: data.rightSideText,
    });

    const stylesOverlay = classNames(styles.imgOverlay, {
      [styles.imgOverlayOn]: isHovered && noneOpen,
      [styles.darkGradient]: data.darkText && noneOpen && !isHovered,
    });

    const styleFadeIn = classNames(styles.gradFadeIn, {
      [styles.gradFadeInOn]: isOpen,
    });

    return (
      <article
        key={data._id}
        id={`jalousie-item-${index}`}
        className={classNames(styles.section, {
          [styles.sectionOpen]: isOpen,
        })}
        style={style}
        onClick={trackEvent.wrapOnClick(() => openSection(index), {
          trackLabel: `${index + 1}`,
        })}
        onMouseOver={() => hover(index)}
        data-testid={`jalousie-item-${index}`}
      >
        <div className={stylesOverlay} />

        <div className={stylesInitial}>
          <span
            className={classNames(styles.number, {
              [styles.numberShown]: noneOpen && isHovered,
            })}
          >
            {index + 1}.
          </span>
          <div className={styles.h1}>
            <span>{data.title}</span>
          </div>

          {/* placeholder to correctly position multiline heading */}
          <div className={classNames(styles.h1, styles.h1placeholder)}>
            <span>{data.title}</span>
          </div>

          <span
            className={classNames(styles.readMore, {
              [styles.readMoreShown]: isHovered,
            })}
          >
            <div className={styles.plusIcon}>
              <Icon iconName="general/plus" />
            </div>
            {I18nUtils.localize(locale, 'read more')}
          </span>
        </div>

        <div className={stylesNumber}>{index + 1}.</div>

        <div>
          <div className={stylesContent}>
            <div className={classNames(styles.h1, styleFadeIn)}>
              {data.title}
            </div>

            <div className={classNames(styles.paragraph, styleFadeIn)}>
              <SafeText content={data.description} />
            </div>

            {data.link && (
              <Button
                isPrimaryCta
                href={data.link.url}
                text={data.link.text}
                className={styleFadeIn}
              />
            )}
          </div>

          <div
            className={classNames(
              { [styles.dark]: data.darkText },
              styleFadeIn
            )}
          >
            <Button
              isSmallSquare
              className={classNames(styles.closeButton, {
                [styles.hide]: !isOpen,
              })}
              iconClassName={styles.closeIcon}
              iconName="general/close.svg"
              onClick={(e: any) => {
                e.stopPropagation();
                openSection(defaultIndex);
              }}
            />
            <Button
              isSmallSquare
              className={classNames(styles.leftArrow, {
                [styles.hide]: index - 1 < 0 || !isOpen,
              })}
              iconName="general/arrow-chevron-left.svg"
              iconClassName={styles.arrowSize}
              onClick={(e: any) => {
                e.stopPropagation();
                openSection(index - 1);
              }}
            />
            <Button
              isSmallSquare
              className={classNames(styles.rightArrow, {
                [styles.hide]: index + 1 > 2 || !isOpen,
              })}
              iconName="general/arrow-chevron-right.svg"
              iconClassName={styles.arrowSize}
              onClick={(e: any) => {
                e.stopPropagation();
                openSection(index + 1);
              }}
            />
          </div>
        </div>
      </article>
    );
  }

  function renderSectionMobile(
    data: CfJalousieItem,
    index: number
  ): React.ReactNode {
    const isOpen = state.selectedIndex === index;

    const style = {
      backgroundImage: getImageBackground(data),
      ...getMobileStyle(index),
    };

    const stylesInitial = classNames({
      [styles.hide]: !noneOpen,
      [styles.initial]: noneOpen,
    });

    const stylesNumber = classNames(styles.numberBottom, {
      [styles.hide]: noneOpen,
      [styles.numberOpened]: isOpen,
    });

    const stylesContent = classNames(styles.content);

    const stylesOverlay = classNames(styles.imgOverlay, {
      [styles.darkGradient]: !data.darkText,
      [styles.lightGradient]: data.darkText,
    });

    const styleFadeIn = classNames(styles.gradFadeIn, {
      [styles.gradFadeInOn]: isOpen,
    });

    return (
      <article
        key={data._id}
        id={`jalousie-item-${index}`}
        className={classNames(styles.section, {
          [styles.sectionOpen]: isOpen,
        })}
        style={style}
        onClick={trackEvent.wrapOnClick(() => openSection(index), {
          trackLabel: `${index + 1}`,
        })}
      >
        <div className={stylesOverlay} />

        <div className={stylesInitial}>
          <span className={styles.number}>{index + 1}.</span>
          <div className={styles.h1}>
            <span>{data.title}</span>
          </div>

          {/* placeholder to correctly position multiline heading */}
          <div className={classNames(styles.h1, styles.h1placeholder)}>
            <span>{data.title}</span>
          </div>

          <span className={styles.readMore}>
            <div className={styles.plusIcon}>
              <Icon iconName="general/plus" />
            </div>
            {I18nUtils.localize(locale, 'read more')}
          </span>
        </div>

        <div className={stylesNumber}>{index + 1}.</div>

        <div>
          <div className={stylesContent}>
            <div className={classNames(styles.h1, styleFadeIn)}>
              {data.title}
            </div>

            <div className={classNames(styles.paragraph, styleFadeIn)}>
              <SafeText content={data.description} />
            </div>

            {data.link && !data.hideLinkOnMobile && (
              <Button
                isPrimaryCta
                href={data.link.url}
                text={data.link.text}
                className={styleFadeIn}
              />
            )}
          </div>

          <div className={styleFadeIn}>
            <Button
              isSmallSquare
              className={classNames(styles.closeButton, {
                [styles.hide]: !isOpen,
              })}
              iconClassName={styles.closeIcon}
              iconName="general/close.svg"
              onClick={(e: any) => {
                e.stopPropagation();
                openSection(defaultIndex);
              }}
            />
            <Button
              isSmallSquare
              className={classNames(styles.leftArrow, {
                [styles.hide]: index - 1 < 0 || !isOpen,
              })}
              iconName="general/arrow-chevron-left.svg"
              iconClassName={styles.arrowSize}
              onClick={(e: any) => {
                e.stopPropagation();
                openSection(index - 1);
              }}
            />
            <Button
              isSmallSquare
              className={classNames(styles.rightArrow, {
                [styles.hide]: index + 1 > 2 || !isOpen,
              })}
              iconName="general/arrow-chevron-right.svg"
              iconClassName={styles.arrowSize}
              onClick={(e: any) => {
                e.stopPropagation();
                openSection(index + 1);
              }}
            />
          </div>
        </div>
      </article>
    );
  }

  /**
   * Returns css left, right properties for section on particular index for desktop
   */
  function getDesktopStyle(index: number): any {
    const { bordersDesktop } = state;
    const { imageCenter } = items[index];

    const borderPct = [
      [0, bordersDesktop[0]],
      [bordersDesktop[0], bordersDesktop[1]],
      [bordersDesktop[1], 100],
    ];

    const leftBorder = borderPct[index][0];
    const rightBorder = 100 - borderPct[index][1];

    return {
      left: `${leftBorder}%`,
      right: `${rightBorder}%`,
      backgroundPositionX: `${imageCenter * 100}%`,
    };
  }

  /**
   * Returns css left, right properties for section on particular index for mobile
   */
  function getMobileStyle(index: number) {
    const { bordersMobile } = state;

    const borderPct = [
      [0, bordersMobile[0]],
      [bordersMobile[0], bordersMobile[1]],
      [bordersMobile[1], 100],
    ];

    const topBorder = borderPct[index][0];

    return {
      left: 0,
      right: 0,
      top: `${topBorder}%`,
    };
  }
}

function getImageBackground(data: CfJalousieItem) {
  const img = ContentfulUtils.getImage({
    image: data.image,
    imagePhone: data.imagePhone,
  });

  return img
    ? `url(${UrlProviderService.getImageUrl(
        img.assetUrl || img.url,
        img.width,
        img.height
      )})`
    : undefined;
}
