import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import { CfCustomerTestimonial } from '@mycs/contentful';
import { calculateAverage } from 'mycs/shared/utilities/RatingUtils/RatingUtils';
import { FurnitureType, Filter } from 'mycs/types/reviewForm';
import styles from './CustomerTestimonials.scss';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';
import CustomerTestimonial from './CustomerTestimonial/CustomerTestimonial';
import StarsRating from 'mycs/shared/components/StarsRating/StarsRating';
import Button from '../Button/Button';

export default function CustomerTestimonials({ }: {}) {
  const { locale, countryCode } = useLocale();
  const customerReviews = useRef<CfCustomerTestimonial[]>([]);
  const [filteredCustomerReviews, setFilteredCustomerReviews] = useState<CfCustomerTestimonial[]>([]);
  const [avgScore, setAvgScore] = useState<number | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<string>(Filter.All);

  useEffect(() => {
    ContentfulService.getCustomerTestimonialsList(locale, countryCode).then(
      (data) => {
        //ignore invalid data
        if (!data || !Array.isArray(data)) {
          return;
        }

        customerReviews.current = data;
        setFilteredCustomerReviews(data);
        const testimonials: CfCustomerTestimonial[] = data.filter(
          (review: CfCustomerTestimonial) => review.showOnWebsite
        );
        const average = calculateAverage(
          testimonials.map((review: CfCustomerTestimonial) => review.rating)
        );
        setAvgScore(average);
      }
    );
  }, [locale, countryCode, setFilteredCustomerReviews]);

  const filterSofasReviews = () => {
    setSelectedFilter(Filter.Sofas);
    setFilteredCustomerReviews(customerReviews.current?.filter(
      (testimonial: CfCustomerTestimonial) => {
        return [FurnitureType.Pyllow, FurnitureType.Couch, FurnitureType.Joyn,
        FurnitureType.Cushion, FurnitureType.Styng].includes(testimonial.furnitureType as FurnitureType);
      }));
  };

  const filterShelvesReviews = () => {
    setSelectedFilter(Filter.Shelves);
    setFilteredCustomerReviews(customerReviews.current?.filter(
      (testimonial: CfCustomerTestimonial) => {
        return [FurnitureType.Shelf, FurnitureType.Closet, FurnitureType.Wardrobe,
        FurnitureType.Table, FurnitureType.Couchtable, FurnitureType.Sydetable,
        FurnitureType.Chayr, FurnitureType.Pryme, FurnitureType.Samplebox].includes(testimonial.furnitureType as FurnitureType);
      }));
  };

  const filterAllReviews = () => {
    setSelectedFilter(Filter.All);
    setFilteredCustomerReviews(customerReviews.current);
  };

  const renderPages = () => {
    return <div>{renderCustomerReviews(filteredCustomerReviews)}</div>;
  };

  const renderCustomerReviews = (list: typeof filteredCustomerReviews) => {
    return list?.map((review) => {
      return <CustomerTestimonial key={review._id} review={review} />;
    });
  };

  const renderFilters = () => {
    return Object.keys(Filter).map((filter) => {
      return <Button
        isRound={true}
        onClick={() => filterTestimonials(filter)}
        id={filter}
        key={filter}
        className={`${styles.filterBtn} ${selectedFilter === filter && styles.activeFilter}`}>
        {I18nUtils.localize(locale, filter)}
      </Button>
    });
  };

  const filterTestimonials = (filter: string) => {
    switch (filter) {
      case Filter.All:
        filterAllReviews();
        break;
      case Filter.Shelves:
        filterShelvesReviews();
        break;
      case Filter.Sofas:
        filterSofasReviews();
        break;
      default:
        return;
    }
  }

  return (
    <div
      className={classNames(styles.container, 'contentContainer')}
      id="customer-reviews"
    >
      <div className={styles.header}>
        <h3 className={styles.title}>
          {I18nUtils.localize(locale, 'Our customers say')}
        </h3>
        <div className={styles.scoreContainer}>
          {!!avgScore && (
            <div className={styles.score}>
              <span>
                {I18nUtils.localize(locale, 'Rate')}: {avgScore}/5
              </span>
              <div className={styles.starsContainer}>
                <StarsRating
                  rating={avgScore}
                  starClassName={styles.starTitle}
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.reviewsSubheading}>
          <span>{I18nUtils.localize(locale, 'reviews-ten-years')}</span>
          <span>{I18nUtils.localize(locale, 'reviews-current')}</span>
          <div className={styles.filtersContainer}>
            <span>{I18nUtils.localize(locale, 'Filters')}</span>
            {renderFilters()}
          </div>
        </div>
      </div>
      {renderPages()}
    </div>
  );
}
