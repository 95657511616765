import classNames from 'classnames';

import { CfAsset, CfButtonLink } from '@mycs/contentful';
import Alink from 'mycs/shared/components/Alink/Alink';
import Anchor from 'mycs/shared/components/Anchor/Anchor';
import Button from 'mycs/shared/components/Button/Button';
import ContentfulUtils from 'mycs/shared/utilities/ContentfulUtils/ContentfulUtils';
import SafeText from 'mycs/shared/components/SafeText/SafeText';
import SmartImage from 'mycs/shared/components/SmartImage/SmartImage';
import StretchBanner from 'mycs/landing-page/components/StretchBanner/StretchBanner';

import styles from './HeaderImage.scss';

type Props = {
  blurb?: string;
  h1?: string;
  lowresUrl?: string;
  gradientOverlay?: boolean;
  image?: CfAsset;
  imageTablet?: CfAsset;
  imagePhone?: CfAsset;
  heading?: string;
  anchors?: any[];
  carousel?: boolean;
  button?: CfButtonLink;
  headlineBar?: boolean;
  headlineBarImage?: CfAsset;
  textColour?: string;
  textClass?: string;
  isCentered?: boolean;
  id?: string;
};

export default function HeaderImage({
  anchors,
  blurb,
  button,
  carousel,
  gradientOverlay = true,
  h1,
  heading,
  headlineBar,
  headlineBarImage,
  image,
  imagePhone,
  imageTablet,
  textClass,
  textColour = 'Light',
  isCentered,
  id,
}: Props) {
  const isPlaceholder =
    (heading && /^[_\s]+$/.test(heading)) || (blurb && /^[_\s]+$/.test(blurb));

  let colourMode = '';
  if (textColour === 'Dark' || !image) {
    colourMode = styles.darkMode;
  } else if (textColour === 'Light' && !gradientOverlay) {
    colourMode = styles.noOverlayMode;
  } else {
    colourMode = styles.lightMode;
  }

  // Show green headlinebar instead of banner image [MYCS-10025]
  if (headlineBar)
    return (
      <div id={id}>
        <StretchBanner text={heading} imageUrl={headlineBarImage?.url} />
      </div>
    );

  const img = ContentfulUtils.imageSelect({ image, imageTablet, imagePhone });

  return (
    <div
      className={classNames(styles.banner, {
        [styles.carousel]: carousel,
        [styles.noImage]: !image,
      })}
    >
      <div
        className={classNames(
          styles.container,
          { ['container']: isCentered },
          { [styles.centered]: isCentered }
        )}
      >
        <div className={styles.linksLayer}>
          {img ? (
            <SmartImage
              className={styles.image}
              src={img.url}
              width={img.width}
              height={img.height}
              alt={img.description}
            />
          ) : null}

          {!isPlaceholder && (heading || blurb || h1) ? (
            <div className={classNames(styles.overlay, colourMode)} />
          ) : null}
        </div>
      </div>

      <div
        className={classNames(styles.textContainer, {
          [styles.showPlacehoders]: isPlaceholder,
        })}
      >
        <div className={classNames(styles.textLayer, colourMode, textClass)}>
          {h1 ? <h1 className={styles.subtitle}>{h1}</h1> : null}

          {heading ? (
            <h3 className={styles.title}>
              <span>{heading}</span>
            </h3>
          ) : null}

          {blurb ? (
            <SafeText className={styles.subtitle} content={blurb} />
          ) : null}

          {button ? (
            <div className={styles.button}>
              <Button
                isPrimaryCta
                text={button.text}
                href={button.url}
                trackLabel="CTA"
                nativeProps={
                  button.url ? { target: getLinkTarget(button.url) } : {}
                }
              />
            </div>
          ) : null}

          {anchors ? (
            <div className={styles.anchors}>
              {anchors.map(({ text, url }, key) => (
                <div className={styles.anchorItem} key={key}>
                  <Anchor destination={url} text={text} />
                </div>
              ))}
            </div>
          ) : null}

          {renderLinkLayer() /* a link layer for the text block */}
        </div>

        {renderLinkLayer() /* a link layer for the entire header image */}
      </div>
    </div>
  );

  /**
   * Get a target attribute value depending on the URL
   */
  function getLinkTarget(
    url: string
  ): '_blank' | 'none' | '_self' | '_top' | undefined {
    const isExternalLink = /utm_source=/.test(url);
    return isExternalLink ? '_blank' : undefined;
  }

  /**
   * A link that makes the whole image block clickable without interferring
   * with achnor links and a CTA on top of it.
   */
  function renderLinkLayer(): React.ReactNode {
    return button ? (
      <Alink
        className={styles.linkLayer}
        href={button.url}
        text={'main link'}
        target={button.url ? getLinkTarget(button.url) : undefined}
      >
        {' '}
      </Alink>
    ) : null;
  }
}
