import { Component, Fragment } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import _groupBy from 'lodash/groupBy';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';

import type {
  CfProductFilterOption,
  CfProductFilters,
  CfGridBanner,
} from '@mycs/contentful';
import {
  ExtendedFilterOption,
  FormattedOption,
  initValidURLQueryParameters,
  isCheckboxOption,
  isRangeOption,
  PricesDimensionsRanges,
  QueryParameters,
  validateFilters,
  ValidQueryParameters,
} from './filterable-products-helpers';
import { ExtendedFilter, ProductFilters } from './ProductFilters';
import type { Design } from 'mycs/shared/services/DesignApiService/DesignApiService';
import { Tab } from 'mycs/shared/components/Pillbox/Pillbox';
import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import { usePricesDimensionsRanges } from './usePricesDimensionsRanges';
import AnalyticsService from 'mycs/shared/services/AnalyticsService/AnalyticsService';
import Button from 'mycs/shared/components/Button/Button';
import DesignApiService from 'mycs/shared/services/DesignApiService/DesignApiService';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import Logger from 'mycs/shared/services/Logger';
import ProductsGrid from 'mycs/shared/components//ProductsGrid/ProductsGrid';
import SafeText from 'mycs/shared/components/SafeText/SafeText';
import WindowLocationUtils from 'mycs/shared/utilities/WindowLocationUtils/WindowLocationUtils';
import ProductFiltersPlaceholder from 'mycs/shared/components/Placeholder/ProductFiltersPlaceholder/ProductFiltersPlaceholder';
import ProductGridPlaceholder from 'mycs/shared/components/Placeholder/ProductGridPlaceholder/ProductGridPlaceholder';

import styles from './FilterableProducts.scss';

interface Props {
  data: CfProductFilters;
  initialDesigns?: any[];
  gridBanners?: CfGridBanner[];
  bannerDesigns?: Design[];
  filterUndockElementOffset?: number;
  perPage?: number;
  isPdpLink?: boolean | null;
}

interface State {
  designs: any[] | null;
  defaultDesigns: any[] | null;
  activeTabName: string | null;
  activeOptions: ExtendedFilterOption[];
  appliedOptions: ExtendedFilterOption[];
  isLoading: boolean;
  isMobileFilterOpen: boolean;
  isErrorMessageOpen: boolean;
  productsContainerRef: any;
}

export default function FilterableProducts(props: Props) {
  const navigate = useNavigate();
  const { locale, countryCode } = useLocale();
  const { hasPhoneDimensions } = useDevice();
  const pricesDimensionsRanges = usePricesDimensionsRanges(
    props.data.furnitureGroup
  );
  const { search } = useLocation();
  const parsedSearch = new URLSearchParams(search);

  const searchParams = Object.fromEntries(parsedSearch);

  const filters = props.data.filters.map((filter) => ({
    ...filter,
    filterOptions: filter.filterOptions.map((filterOption) => ({
      ...filterOption,
      tabName: filter.filterName,
    })),
  }));

  /**
   * Get formatted filter tabs
   */
  const filterTabs: Tab[] =
    filters?.map(
      (filter): Tab => ({
        id: filter.filterName,
        name: filter.label,
        trackingSlug: filter.filterName,
      })
    ) ?? [];

  // Set valid browser URL query parameters
  const validQueryParameters = initValidURLQueryParameters(
    props.data.furnitureGroup,
    pricesDimensionsRanges,
    filters
  );

  return (
    <InnerFilterableProducts
      {...props}
      filters={filters}
      filterTabs={filterTabs}
      navigate={navigate}
      locale={locale}
      searchParams={searchParams}
      pricesDimensionsRanges={pricesDimensionsRanges}
      validQueryParameters={validQueryParameters}
      countryCode={countryCode}
      isSmallScreen={hasPhoneDimensions}
    />
  );
}

type InnerProps = Props & {
  navigate: ReturnType<typeof useNavigate>;
  locale: string;
  countryCode: string;
  isSmallScreen: boolean;
  filters: ExtendedFilter[];
  pricesDimensionsRanges: PricesDimensionsRanges | null;
  validQueryParameters: ValidQueryParameters;
  filterTabs: Tab[];
  searchParams: QueryParameters;
};

class InnerFilterableProducts extends Component<InnerProps, State> {
  defaultQueryParams: QueryParameters;
  currentQueryParams: QueryParameters;

  constructor(props: InnerProps) {
    super(props);

    this.state = {
      designs: null,
      defaultDesigns: null,
      activeTabName: null,
      activeOptions: [],
      appliedOptions: [],
      isLoading: false,
      isMobileFilterOpen: false,
      isErrorMessageOpen: false,
      productsContainerRef: null,
    };

    this.defaultQueryParams = {
      furniture_group: props.data.furnitureGroup,
      country_code: props.countryCode,
    };

    this.currentQueryParams = _cloneDeep(this.defaultQueryParams);
  }

  /**
   * Get filtered designs
   */
  getFilteredDesigns(queryParams: QueryParameters) {
    this.setState({ isLoading: true });

    return DesignApiService.getFilteredDesigns(
      queryParams,
      this.props.countryCode
    )
      .then((designs) => {
        this.setState({ isLoading: false });
        return designs;
      })
      .catch((err) => {
        Logger.error(
          "<FilterableProducts>: Couldn't load filtered designs",
          err
        );
        return [];
      });
  }

  /**
   * Get initial designs
   */
  getInitialDesigns() {
    const { initialDesigns } = this.props;
    return initialDesigns
      ? DesignApiService.getDesignsByUuids(
          initialDesigns,
          this.props.countryCode,
          false
        ).catch((err) => {
          Logger.error("<FilterableProducts>: Couldn't load designs", err);
          return [];
        })
      : [];
  }

  /**
   * Get formatted list of applied options (dimensions options grouped in one option)
   */
  getFormattedAppliedOptions() {
    const { appliedOptions } = this.state;
    if (appliedOptions.length === 0) return undefined;

    const options: any[] = [];
    appliedOptions.forEach((appliedOption) => {
      if (isRangeOption(appliedOption)) {
        if (isCheckboxOption(appliedOption)) {
          const index = options.findIndex(
            (option: any) => option.name === appliedOption.label
          );
          if (index < 0) {
            options.push({
              name: appliedOption.label,
              dimensionCheckboxOptions: [appliedOption],
              ...appliedOption,
            });
          } else {
            options[index].dimensionCheckboxOptions.push(appliedOption);
          }
        } else {
          const groupName = appliedOption.queryParam.split('_')[0];
          const groupIndex = options.findIndex(
            (option: any) => option.name === groupName
          );
          if (groupIndex < 0) {
            options.push({
              name: groupName,
              rangeValue: [appliedOption.queryParamValue],
              ...appliedOption,
            });
          } else {
            options[groupIndex].rangeValue.push(appliedOption.queryParamValue);
          }
        }
      } else {
        options.push(appliedOption);
      }
    });

    return options;
  }

  /**
   * Get formatted range labels
   */
  getFormattedRangeLabel(
    rangeType: string,
    rangeValue: string[],
    rangeLabel = ''
  ) {
    // Sort range values from min to max
    const range = rangeValue
      .map((value) => {
        return Number(value);
      })
      .sort((a, b) => a - b);

    switch (rangeType) {
      case 'price':
        return `${I18nUtils.localizePrice(
          range[0],
          0,
          this.props.locale
        )} - ${I18nUtils.localizePrice(range[1], 0, this.props.locale)}`;
      case 'width':
      case 'height':
      case 'length':
        return `${I18nUtils.localize(
          this.props.locale,
          rangeLabel
        )}: ${Math.ceil(range[0] / 10)} cm - ${Math.ceil(range[1] / 10)} cm`;
      default:
        return rangeValue;
    }
  }

  /**
   * Get formatted list of browser URL query parameters from applied filter options
   * i.e.: [{ name: "color_group", value: "oranges-reds", values: ["oranges", "reds"] }]
   */
  getFormattedUrlQueryParams() {
    const { appliedOptions } = this.state;
    const options: any[] = [];

    appliedOptions.forEach((appliedOption: CfProductFilterOption) => {
      // Handle translations
      const paramName = appliedOption.queryParamUrl;
      const paramValue = isRangeOption(appliedOption)
        ? appliedOption.queryParamValue
        : appliedOption.queryParamValueUrl;
      // For prices and dimensions, merge min and max query parameters into one
      const groupName = isRangeOption(appliedOption)
        ? paramName.split('_')[0]
        : paramName;
      const groupIndex = options.findIndex(
        (option: any) => option.name === groupName
      );
      if (groupIndex < 0) {
        options.push({
          name: groupName,
          value: paramValue,
          values: [paramValue],
        });
      } else {
        // If there are several query parameter values, concatenate them with '-'
        // For dimensions checkbox options, only use one query parameter value
        // (i.e.: height_min=400 & height_max=400 -> height=400)
        options[groupIndex].values.push(paramValue);
        options[groupIndex].value = isCheckboxOption(appliedOption)
          ? options[groupIndex].values[0]
          : options[groupIndex].values.join('-');
      }
    });

    return options;
  }

  /**
   * Set query parameters
   */
  setQueryParams(name: string, values: string[], multiSelection = false) {
    if (values.length === 1) {
      // If the param is already set, add value to existing one(s)
      if (multiSelection && this.currentQueryParams[name]) {
        this.currentQueryParams[name] = this.currentQueryParams[name].concat(
          ',',
          values[0]
        );
      } else {
        this.currentQueryParams[name] = values[0];
      }
    } else {
      // Concatenate the query parameter values
      this.currentQueryParams[name] = values.join(',');
    }
  }

  /**
   * Remove query parameter
   */
  removeQueryParam(name: string, value: string) {
    const values = this.currentQueryParams[name].split(',');

    if (values.length === 1) {
      this.currentQueryParams = _omit(
        _cloneDeep(this.currentQueryParams),
        name
      );
    } else {
      values.splice(values.indexOf(value), 1);
      this.currentQueryParams[name] = values.join(',');
    }

    // Reset to default query params if they exist
    if (this.defaultQueryParams[name]) {
      this.currentQueryParams[name] = this.defaultQueryParams[name];
    }
  }

  /**
   * Reset filter and update designs
   */
  resetOption = (option: FormattedOption) => {
    const appliedOptions = _cloneDeep(this.state.appliedOptions);
    const activeOptions = _cloneDeep(this.state.activeOptions);

    // Remove corresponding query parameter and tag
    if (isRangeOption(option)) {
      if (isCheckboxOption(option) && option.dimensionCheckboxOptions) {
        option.dimensionCheckboxOptions.forEach((checkboxOption) => {
          this.removeQueryParam(
            checkboxOption.queryParam,
            checkboxOption.queryParamValue
          );

          const appliedOptionIndex = appliedOptions.findIndex(
            (appliedOption) =>
              appliedOption.queryParam === checkboxOption.queryParam
          );
          if (appliedOptionIndex >= 0) {
            appliedOptions.splice(appliedOptionIndex, 1);
          }
          const activeOptionIndex = activeOptions.findIndex(
            (activeOption) =>
              activeOption.queryParam === checkboxOption.queryParam
          );
          if (activeOptionIndex >= 0) {
            activeOptions.splice(activeOptionIndex, 1);
          }
        });
      } else {
        const rangesQueryParams = [`${option.name}_min`, `${option.name}_max`];
        rangesQueryParams.forEach((queryParam) => {
          this.removeQueryParam(queryParam, option.queryParamValue);

          const appliedOptionIndex = appliedOptions.findIndex(
            (appliedOption) => appliedOption.queryParam === queryParam
          );
          if (appliedOptionIndex >= 0) {
            appliedOptions.splice(appliedOptionIndex, 1);
          }
          const activeOptionIndex = activeOptions.findIndex(
            (activeOption) => activeOption.queryParam === queryParam
          );
          if (activeOptionIndex >= 0) {
            activeOptions.splice(activeOptionIndex, 1);
          }
        });
      }
    } else {
      this.removeQueryParam(option.queryParam, option.queryParamValue);

      const appliedOptionIndex = appliedOptions.findIndex(
        (appliedOption) =>
          appliedOption.queryParam === option.queryParam &&
          appliedOption.queryParamValue === option.queryParamValue
      );
      if (appliedOptionIndex >= 0) {
        appliedOptions.splice(appliedOptionIndex, 1);
      }
      const activeOptionIndex = activeOptions.findIndex(
        (activeOption) =>
          activeOption.queryParam === option.queryParam &&
          activeOption.queryParamValue === option.queryParamValue
      );
      if (activeOptionIndex >= 0) {
        activeOptions.splice(activeOptionIndex, 1);
      }
    }

    this.setState({ appliedOptions, activeOptions }, () => {
      this.updateDesigns(true);
    });
  };

  /**
   * Toggle display of mobile filter options
   */
  toggleMobileFilter = (isOpen: boolean) => {
    const isMobileFilterOpen =
      isOpen !== undefined ? isOpen : !this.state.isMobileFilterOpen;
    this.setState({ isMobileFilterOpen });
  };

  /**
   * Track filter event on submit of filter options
   */
  trackFilterEvent() {
    const formattedOptions = this.getFormattedAppliedOptions();
    if (!formattedOptions) return;

    let action = '';
    let label = '';

    // Format options label (i.e.: range option -> '120€ - 600€' or normal option -> 'Blue')
    const getFormattedOptionsLabel = (options: FormattedOption[]) => {
      return options.map((option) =>
        option.rangeValue
          ? this.getFormattedRangeLabel(
              option.name,
              option.rangeValue,
              option.label
            )
          : option.label || ''
      );
    };

    if (this.props.isSmallScreen) {
      // On mobile: group options per tab name
      const currentOptionsGroupedByTabName = _groupBy(
        formattedOptions,
        'tabName'
      );
      // Concatenate tab names (i.e.: 'color_material')
      const tabNames = Object.keys(currentOptionsGroupedByTabName);
      action = tabNames.join('_');
      // Concatenate labels per tab name (i.e.: [ 'Blue,Green', 'Wood' ])
      const formattedLabels = tabNames.map((tabName) => {
        const labels = getFormattedOptionsLabel(
          currentOptionsGroupedByTabName[tabName]
        );
        return labels.join(',');
      });
      // Concatenate result labels (i.e.: 'Blue,Green_Wood')
      label = formattedLabels.join('_');
    } else {
      // On desktop: send an event for the current tab
      const { activeTabName } = this.state;
      if (activeTabName) {
        action = activeTabName;
      }
      const currentOptions = formattedOptions.filter(
        (option) => option.tabName === activeTabName
      );
      // Concatenate labels (i.e.: 'Blue,Green')
      const labels = getFormattedOptionsLabel(currentOptions);
      label = labels.join(',');
    }

    AnalyticsService.eventTrack('filter', this.props.locale, {
      action,
      label,
    });
  }

  /**
   * Reset selected filter and close filter options
   */
  onCancel = () => {
    this.setState({ activeTabName: null, activeOptions: [] });
  };

  /**
   * Set active filter on tab change
   */
  onTabChange = (activeTab: Tab) => {
    if (!activeTab.id) return;
    this.setState({ activeTabName: activeTab.id, activeOptions: [] });
  };

  /**
   * Update active options
   */
  onUpdateActiveOptions = (currentActiveOptions: ExtendedFilterOption[]) => {
    this.setState({ activeOptions: currentActiveOptions });
  };

  /**
   * Remove options from active options per tab
   */
  onResetActiveOptions = (currentActiveOptions: ExtendedFilterOption[]) => {
    this.setState({ activeOptions: currentActiveOptions });
  };

  /**
   * Apply filter and update designs
   */
  onSubmitActiveOptions = () => {
    const appliedOptions = _cloneDeep(this.state.activeOptions);
    const optionsGroupedByQueryParam = _groupBy(appliedOptions, 'queryParam');

    // Update query parameters
    this.currentQueryParams = _cloneDeep(this.defaultQueryParams);
    Object.keys(optionsGroupedByQueryParam).forEach((queryParam) => {
      const queryParamValues = optionsGroupedByQueryParam[queryParam].map(
        (option) => option.queryParamValue
      );
      this.setQueryParams(queryParam, queryParamValues);
    });

    this.setState(
      {
        appliedOptions,
        activeOptions: [],
        isMobileFilterOpen: false,
        isErrorMessageOpen: false,
      },
      () => {
        this.trackFilterEvent();
        this.updateDesigns();
      }
    );
  };

  /**
   * Apply filter and update designs
   */
  onSubmitActiveOptionsMobile = () => {
    const appliedOptions = this.state.activeOptions;
    const optionsGroupedByQueryParam = _groupBy(appliedOptions, 'queryParam');

    // Update query parameters
    this.currentQueryParams = _cloneDeep(this.defaultQueryParams);
    Object.keys(optionsGroupedByQueryParam).forEach((queryParam) => {
      const queryParamValues = optionsGroupedByQueryParam[queryParam].map(
        (option) => option.queryParamValue
      );
      this.setQueryParams(queryParam, queryParamValues);
    });

    this.setState(
      {
        appliedOptions,
        isMobileFilterOpen: false,
        isErrorMessageOpen: false,
      },
      () => {
        this.trackFilterEvent();
        this.updateDesigns();
      }
    );
  };

  onRefChange = (node: any) => {
    this.setState({ productsContainerRef: node });
  };

  onCloseErrorMessage = () => {
    this.setState({ isErrorMessageOpen: false });
  };

  /**
   * Update browser URL query parameters according to applied filters
   * It will create a new history record in case of a new parameter
   */
  updateURL(isInit = false) {
    const { pathname, searchParams: currentSearchParams } = new URL(
      window.location.href
    );
    const newSearchParams = new URLSearchParams();

    const searchParams = isInit ? currentSearchParams : newSearchParams;

    const queryParams = this.getFormattedUrlQueryParams();

    queryParams.forEach((queryParam) => {
      searchParams.set(queryParam.name, queryParam.value);
    });

    // If cannot find param in search params, remove it from the URL
    searchParams.forEach((_, searchParamName) => {
      if (
        Object.keys(this.props.validQueryParameters).includes(
          searchParamName
        ) &&
        !queryParams.find((param) => param.name === searchParamName)
      ) {
        searchParams.delete(searchParamName);
      }
    });

    const oldSearch = currentSearchParams.toString();
    const newSearch = searchParams.toString();

    //there is no need to update the url if the search string is the same
    if (newSearch !== oldSearch) {
      this.props.navigate({
        pathname,
        search: newSearch,
      });
    }
  }

  /**
   * Update the designs and reset the selected filter
   */
  async updateDesigns(isRemoving = false, isInit = false) {
    let designs: any[] | null = [];
    if (
      this.props.initialDesigns &&
      _isEqual(this.currentQueryParams, this.defaultQueryParams)
    ) {
      designs = await this.getInitialDesigns();
    } else {
      designs = await this.getFilteredDesigns(this.currentQueryParams);
    }

    // If no designs are found:
    if (designs && designs.length === 0) {
      let appliedOptions = _cloneDeep(this.state.appliedOptions);

      // And if filter options are being removed, reset to default designs
      if (isRemoving) {
        this.currentQueryParams = _cloneDeep(this.defaultQueryParams);
        appliedOptions = [];
        designs = this.state.defaultDesigns;
      } else {
        // Otherwise, remove the last query parameter until it returns a list of designs
        for (let i = appliedOptions.length - 1; i >= 0; i--) {
          // If the filter options are ranges, loop and remove each query param associated
          if (isRangeOption(appliedOptions[i])) {
            this.removeQueryParam(
              appliedOptions[i].queryParam,
              appliedOptions[i].queryParamValue
            );
            appliedOptions.splice(i, 1);

            if (i === 0) {
              const newDesigns = await this.getFilteredDesigns(
                this.currentQueryParams
              );
              if (newDesigns.length > 0) {
                designs = _cloneDeep(newDesigns);
                break;
              }
            }
          } else {
            this.removeQueryParam(
              appliedOptions[i].queryParam,
              appliedOptions[i].queryParamValue
            );
            appliedOptions.splice(i, 1);
            const newDesigns = await this.getFilteredDesigns(
              this.currentQueryParams
            );
            if (newDesigns.length > 0) {
              designs = _cloneDeep(newDesigns);
              break;
            }
          }
        }
      }

      this.setState(
        {
          designs,
          activeTabName: null,
          activeOptions: [],
          appliedOptions,
          isErrorMessageOpen: true,
        },
        () => {
          this.updateURL();
        }
      );
    } else {
      this.setState(
        {
          designs,
          activeTabName: null,
          isErrorMessageOpen: false,
        },
        () => {
          this.updateURL(isInit);
        }
      );
    }
  }

  /**
   * Initialize default values and load default designs
   */
  async init() {
    const {
      initialDesigns,
      data: { furnitureGroup },
      validQueryParameters,
    } = this.props;

    let { searchParamsToRemove, validSearchParams, appliedOptions } =
      validateFilters(
        validQueryParameters,
        furnitureGroup,
        this.props.searchParams
      );

    if (searchParamsToRemove.length > 0) {
      WindowLocationUtils.removeSearchParam(searchParamsToRemove);
    }

    if (validSearchParams.length > 0) {
      for (const { name, values, multiSelection } of validSearchParams) {
        this.setQueryParams(name, values, multiSelection);
      }
    }

    // Set default designs (either coming from Contentful or filters endpoint)
    const defaultDesigns = initialDesigns
      ? await this.getInitialDesigns()
      : await this.getFilteredDesigns(this.currentQueryParams);

    this.setState({ defaultDesigns, appliedOptions }, () => {
      this.updateDesigns(false, true);
    });
  }

  async componentDidMount() {
    try {
      await this.init();
    } catch (error) {
      Logger.error(`failed to init: ${error}`);
    }
  }

  renderErrorMessage(): JSX.Element {
    const { isErrorMessageOpen } = this.state;
    const { locale } = this.props;

    return (
      <div
        className={classNames(
          'contentContainer',
          styles.errorMessageContainer,
          {
            [styles.isOpen]: isErrorMessageOpen,
          }
        )}
      >
        <div className={styles.errorMessage}>
          <SafeText
            className={styles.errorMessageText}
            content={I18nUtils.localize(
              locale,
              'Sorry! No new results found. Choose other filter options.'
            )}
          />

          <div className={styles.errorMessageCloseButton}>
            <Button
              iconName="general/close.svg"
              onClick={this.onCloseErrorMessage}
            />
          </div>
        </div>
      </div>
    );
  }

  renderTags(): JSX.Element {
    const options = this.getFormattedAppliedOptions();
    if (!options) return <Fragment />;

    return (
      <div className={classNames('contentContainer', styles.tagsContainer)}>
        {options.map((option: FormattedOption, index: number) => (
          <div
            key={index}
            className={styles.tagContainer}
            onClick={() => this.resetOption(option)}
          >
            {option.rangeValue
              ? this.getFormattedRangeLabel(
                  option.name,
                  option.rangeValue,
                  option.label
                )
              : option.label}

            <div className={styles.tagCloseButton}>
              <Button iconName="general/close.svg" />
            </div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const {
      gridBanners,
      bannerDesigns,
      isSmallScreen,
      data: { furnitureGroup },
      filters,
      filterTabs,
      filterUndockElementOffset,
      pricesDimensionsRanges,
      isPdpLink,
    } = this.props;
    const { designs, activeTabName, isLoading, activeOptions, appliedOptions } =
      this.state;

    // Render placeholder component while loading
    if (!furnitureGroup || !designs)
      return (
        <div className={styles.placeholderAnimation}>
          <ProductFiltersPlaceholder />
          <ProductGridPlaceholder />
        </div>
      );

    return (
      <div>
        <ProductFilters
          filters={filters}
          activeOptions={activeOptions}
          appliedOptions={appliedOptions}
          filterTabs={filterTabs}
          filterUndockElementOffset={filterUndockElementOffset}
          pricesDimensionsRanges={pricesDimensionsRanges}
          activeTabName={activeTabName}
          isLoading={isLoading}
          {...{
            productsContainerRef: this.state.productsContainerRef,
            formattedOptions: this.getFormattedAppliedOptions(),
            isMobileFilterOpen: this.state.isMobileFilterOpen,
            onTabChange: this.onTabChange,
            onUpdateActiveOptions: this.onUpdateActiveOptions,
            onResetActiveOptions: this.onResetActiveOptions,
            onSubmitActiveOptions: this.onSubmitActiveOptions,
            onCancel: this.onCancel,
            onSubmitActiveOptionsMobile: this.onSubmitActiveOptionsMobile,
            toggleMobileFilter: this.toggleMobileFilter,
          }}
        />

        <div
          ref={this.onRefChange}
          className={classNames(styles.productsContainer, {
            [styles.showOverlay]: activeTabName && !isLoading,
            [styles.isLoading]: isLoading,
          })}
          onClick={activeTabName ? this.onCancel : undefined}
        >
          {this.renderErrorMessage()}
          {this.renderTags()}

          <ProductsGrid
            designs={designs}
            previewProps={{
              hasAddToCart: false,
              isPdpLink: isSmallScreen || isPdpLink,
              showImagesCarousel: !isSmallScreen,
            }}
            hideFurnitureTypeFilter
            gridBanners={gridBanners}
            bannerDesigns={bannerDesigns}
            perPage={this.props.perPage}
          />
        </div>
      </div>
    );
  }
}
