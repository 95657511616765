import DesignApiService from 'mycs/shared/services/DesignApiService/DesignApiService';
import type { Design } from 'mycs/shared/services/DesignApiService/DesignApiService';
import useSWRInfinite from 'swr/infinite';

export function useDesignsByCategory(
  preloadedDesigns: Design[],
  category: string | undefined,
  countryCode: string
) {
  const { data, setSize, isValidating, isLoading } = useSWRInfinite(
    (pageIndex, previousPageData) => {
      if (!category && previousPageData && !previousPageData.length)
        return null;

      return [pageIndex];
    },
    ([pageIndex]) =>
      DesignApiService.getDesignsByCategory(category!, countryCode, {
        //+2 to ignore first page that is already preloaded
        page: pageIndex + 2,
      }),
    {
      initialSize: 0,
      revalidateOnFocus: false,
      revalidateFirstPage: false,
    }
  );

  const designs = [...preloadedDesigns, ...(data?.flat() ?? [])];

  const hasReachedEnd = data?.[data.length - 1]?.length === 0;

  return {
    designs,
    loadMore() {
      setSize((size) => size + 1);
    },
    isLoading: isValidating || isLoading,
    hasMore: !hasReachedEnd,
  };
}
