import { useMemo } from 'react';
import { useDesigns } from 'mycs/hooks/useDesigns';
import { CfGalleryItem } from '@mycs/contentful';
import type { Design } from 'mycs/shared/services/DesignApiService/DesignApiService';

interface ItemWithDesigns extends CfGalleryItem {
  designs?: Design[];
}

export const useItemsWithDesigns = (
  items: CfGalleryItem[]
): ItemWithDesigns[] => {
  // Get designs using the useDesigns hook
  const designs = useDesigns(items.flatMap((item) => item.uuids ?? []));

  return useMemo(() => {
    // designDict is used to keep track of the designs that have already been added
    // to an item, avoiding duplicates
    const designDict: { [key: string]: boolean } = {};

    // Iterate over items to map each item to a new object with added designs
    return items.map((item) => {
      const newItem: ItemWithDesigns = { ...item };

      if (item.uuids) {
        const uuids = item.uuids;

        // Iterate over uuids to find designs and add them to the newItem object
        uuids.forEach((uuid: string) => {
          // If the design has not been added yet (not present in designDict)
          if (!designDict[uuid]) {
            const design = designs.find((d) => d.uuid === uuid);

            // Add design to the newItem object if it's not already present
            if (
              design &&
              !newItem.designs?.some((d) => d.uuid === design.uuid)
            ) {
              newItem.designs = [...(newItem.designs ?? []), design];
              // Mark the design as added in designDict
              designDict[uuid] = true;
            }
          }
        });
      }

      return newItem;
    });
  }, [items, designs]);
};
