import { memo } from 'react';

import { CfPageMain } from '@mycs/contentful';
import HeaderImage from 'mycs/shared/components/HeaderImage/HeaderImage';
import QuickLinks from 'mycs/404/components/QuickLinks/QuickLinks';

import styles from './Page404.scss';

interface Props {
  data?: CfPageMain;
}

export default memo(function Page404(props: Props) {
  const { data } = props;

  if (!data) return null;

  const headerImage = data.headerImages?.[0];

  return (
    <div>
      {typeof headerImage !== 'undefined' && (
        <HeaderImage
          image={headerImage.image}
          imageTablet={headerImage.imageTablet}
          imagePhone={headerImage.imagePhone}
          lowresUrl={headerImage.imageBase64}
          heading={headerImage.heading}
          blurb={headerImage.blurb}
          button={headerImage.button}
          textColour={headerImage.textColour}
        />
      )}

      <div className={styles.subcatContainer}>
        <h4 className={styles.heading}>{data.subcategoriesTitle}</h4>
        <div className="container">
          {typeof data.subcategories !== 'undefined' && (
            <QuickLinks items={data.subcategories} />
          )}
        </div>
      </div>
    </div>
  );
});
