import {
  CfMaterialCategoriesWithSubCategories,
  CfMaterialCategoryForSubcategories,
  CfMaterial,
  CfMaterialSubcategory,
} from '@mycs/contentful';
import intersection from 'lodash/intersection';
import classNames from 'classnames';
import uniqBy from 'lodash/uniqBy';
import { useDevice } from 'mycs/router/DeviceContext';
import Alert from 'mycs/shared/components/Alert/Alert';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import CfEdit from 'mycs/shared/components/CfEdit/CfEdit';
import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import MaterialPreview from 'mycs/samplebox/components/MaterialPreview/MaterialPreview';
import styles from './MaterialCategories.scss';
import { useSampleBox } from 'mycs/hooks/useSampleBox';
import { hasMaterialSample } from 'mycs/services/MaterialSampleService';

export default function MaterialCategories({
  content,
}: {
  content: CfMaterialCategoriesWithSubCategories;
}) {
  const { locale } = useLocale();
  const { setSampleBoxItems } = useSampleBox();
  const { categories } = content;
  if (!categories) return null;
  return (
    <Categories
      categorizedMaterials={categories}
      onToggle={(item: CfMaterial) => {
        setSampleBoxItems([item]);
        const sampleboxUrl = RelativeUrlService.getSampleboxUrl(locale);
        window.open(sampleboxUrl, '_blank');
      }}
    />
  );
}

type CategoriesProps = {
  onToggle: (item: any) => void;
  categorizedMaterials: CfMaterialCategoryForSubcategories[];
  checked?: any[];
};

function Categories({
  onToggle,
  categorizedMaterials,
  checked = [],
}: CategoriesProps) {
  const { hasPhoneDimensions } = useDevice();
  const { sampleBoxItems } = useSampleBox();

  const filterMaterials = (materials: CfMaterial[]) => {
    if (checked.length === 0) return materials;
    return materials.filter(
      (mat) =>
        intersection(mat.furnitureType, checked).length > 0 ||
        checked.includes(mat.type)
    );
  };

  const renderSubCategory = (group: CfMaterialSubcategory): React.ReactNode => {
    const { materials, _id, name, infoMessage } = group;

    let filteredMaterials = filterMaterials(materials ?? []);
    if (filteredMaterials.length === 0 && !infoMessage) return;
    filteredMaterials = uniqBy(filteredMaterials, (i) => i._id);

    const previews = filteredMaterials.map((item) => (
      <div className={styles.materialPreview} key={item._id}>
        <MaterialPreview
          material={item}
          onToggle={onToggle}
          isAdded={hasMaterialSample(sampleBoxItems, item)}
          noHighlight
          small={hasPhoneDimensions}
          // TODO: these should be adjustable through contentful
          plain={true}
          disableSampleboxMode={true}
        />
      </div>
    ));

    return (
      <div className={styles.materialCategoryContainer}>
        <div className={styles.cfButton}>
          <CfEdit entryId={_id} isStatic />
        </div>

        <h3
          className={classNames(styles.title, {
            [styles.centerTitle]: hasPhoneDimensions,
          })}
        >
          {name}
        </h3>

        {infoMessage && (
          <div>
            <Alert
              text={infoMessage.text}
              button={infoMessage.button}
              isInfoMessage
              isLeftAligned
            />
          </div>
        )}

        {filteredMaterials.length > 0 && (
          <div className={styles.materials}>{previews}</div>
        )}
      </div>
    );
  };

  return (
    <div className={classNames(styles.container)}>
      {categorizedMaterials.map((category) =>
        category.subCategories.map((subCat, key) => (
          <div key={key}>{renderSubCategory(subCat)}</div>
        ))
      )}
    </div>
  );
}
