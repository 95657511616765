import { Subject } from 'rxjs';

import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import DesignApiService from 'mycs/shared/services/DesignApiService/DesignApiService';

/**
 * Maintain the state of the Contact form
 */
class ContactStore extends Subject<any> {
  state: { isOpen: boolean };
  /**
   * Create a store instance
   */
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  /**
   * Get the state of the contact form
   */
  isOpen(): boolean {
    return this.state.isOpen;
  }

  /**
   * Toggle the state of the contact form
   *
   * @returns Sometimes it return a promise
   */
  toggle(locale: string, pathname: string): void | Promise<any> {
    this.state.isOpen = !this.state.isOpen;
    if (
      this.state.isOpen &&
      RelativeUrlService.isConfiguratorPage(pathname, locale)
    ) {
      return DesignApiService.saveCurrentDesign().then(() => {
        this.next(this.state);
      });
    }
    this.next(this.state);
  }
}

export default new ContactStore();
