import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useDevice } from 'mycs/router/DeviceContext';
import { CfSimpleNav } from '@mycs/contentful';
import Anchor from 'mycs/shared/components/Anchor/Anchor';
import CollapsingPanel from 'mycs/shared/components/CollapsingPanel/CollapsingPanel';
import UIUtils from 'mycs/shared/utilities/UIUtils/UIUtils';

import styles from './SimpleNav.scss';

export default function SimpleNav({ content }: { content: CfSimpleNav }) {
  const { hasPhoneDimensions } = useDevice();
  const { hash } = useLocation();
  const [currentSection, setSection] = useState('');

  useEffect(() => {
    // On first-load, scroll to the corresponding section
    // in case a hash/anchor is part of the URL
    if (currentSection !== hash) {
      UIUtils.scrollToElement(hash);
      setSection(hash);
    }
  }, [hash]);

  /**
   * Render a nav link
   */
  function renderLink(link: any, idx: number): React.ReactNode {
    return (
      <div className={styles.item} key={`${link.urlParameter}_${idx}`}>
        <Anchor text={link.title} destination={`${link.urlParameter}`} />
      </div>
    );
  }

  const { links, mobileMenuLabel } = content;
  const items = links.map(renderLink);

  return (
    <div className={styles.nav}>
      {hasPhoneDimensions ? (
        <CollapsingPanel
          iconName="general/arrow-chevron-down.svg"
          isCompactLayout
          isTransparent
          iconClassName={styles.chevron}
          className={styles.panel}
          contentClassName={styles.panelContent}
          headClassName={styles.panelHead}
          headlineClassName={styles.panelHeadline}
          headline={mobileMenuLabel}
          content={<div>{items}</div>}
        />
      ) : (
        items
      )}
    </div>
  );
}
