import classNames from 'classnames';

import { CfCategoriesPreviews } from '@mycs/contentful';
import MediaPreview from 'mycs/shared/components/MediaPreview/MediaPreview';
import LinkedCategories from 'mycs/shared/components/LinkedCategories/LinkedCategories';
import styles from './CatPreviews.scss';

type Props = {
  content: CfCategoriesPreviews;
};

export default function CatPreviews({
  content: {
    title,
    categories,
    darkText,
    textAlign,
    bigTitle,
    useLinkedCategoriesView,
  },
}: Props) {
  const containerClass = classNames(styles.container, {
    [styles.even]: categories && categories.length % 2 === 0,
  });
  // Sizes for SmartImage
  const imageSizes =
    '(max-width: 767px) 90vw, (min-width: 768px) and (max-width: 1280px) 50vw, 30vw';

  if (useLinkedCategoriesView) {
    return (
      <LinkedCategories
        categories={categories}
        twoColumnsLayout={categories.length % 2 === 0}
      />
    );
  }

  return (
    <div>
      {title && (
        <h4
          className={classNames(styles.heading, {
            [styles.bigHeading]: bigTitle,
          })}
        >
          {title}
        </h4>
      )}

      <div
        className={classNames(containerClass, {
          [styles.darkText]: darkText,
        })}
        style={{ textAlign: textAlign ?? 'left' }}
      >
        {categories?.map((category, key) => (
          <div className={classNames(styles.preview)} key={key}>
            <MediaPreview
              title={<span className={styles.title}>{category.title}</span>}
              subtitle={
                category.text ? (
                  <div className={styles.subtitle}>{category.text}</div>
                ) : undefined
              }
              imageSrc={category.image?.url}
              imageAlt={category.image?.description}
              imageTitle={category.image?.title}
              imageWidth={category.image?.width}
              imageHeight={category.image?.height}
              reference={category.url}
              imageSizes={imageSizes}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export function LegacyCatPreviews(
  props: Omit<CfCategoriesPreviews, '_id' | '_contentType' | 'slug'>
) {
  return (
    <CatPreviews
      content={{
        ...props,
        _id: 'legacyCategoryPreviews',
        _contentType: 'wizardFurnitureType',
        slug: 'legacy category previews',
      }}
    />
  );
}
