import { Fragment } from 'react';
import { CfResponsiveImageLike } from '@mycs/contentful';

import ContentfulUtils from 'mycs/shared/utilities/ContentfulUtils/ContentfulUtils';
import CfImage from 'mycs/shared/components/CfImage/CfImage';

type Props = {
  data?: CfResponsiveImageLike;
  containerClass?: string;
  sizes?: string;
};

export function ResponsiveImage({ data, containerClass, sizes }: Props) {
  const img = ContentfulUtils.imageSelect(data);

  if (!img) return <Fragment />;

  const cfImage = <CfImage asset={img} sizes={sizes} />;

  if (containerClass) {
    return <div className={containerClass}>{cfImage}</div>;
  }

  return cfImage;
}
