import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import xorWith from 'lodash/xorWith';

import { CfProductFilter, CfProductFilterOption } from '@mycs/contentful';
import Logger from 'mycs/shared/services/Logger';

interface ValidQueryParameter {
  values: string[];
  options: any;
}

export interface ValidQueryParameters {
  [key: string]: ValidQueryParameter;
}

export interface QueryParameters {
  [key: string]: string;
}

export interface ExtendedFilterOption extends CfProductFilterOption {
  tabName: string;
}

export interface PricesDimensionsRanges {
  [key: string]: string; // 'price_min', 'price_max', 'width_min', 'width_max', 'height_min', 'height_max', 'length_min', 'length_max'
}

export interface FormattedOption {
  name: string;
  rangeValue?: string[];
  dimensionCheckboxOptions?: CfProductFilterOption[];
  slug?: string;
  queryParam: string;
  queryParamValue: string;
  isBoolean?: boolean;
  label?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  tabName?: string | null;
}

export function validateFilters(
  validQueryParameters: ValidQueryParameters,
  furnitureGroup: string,
  searchParams: QueryParameters
): {
  searchParamsToRemove: string[];
  validSearchParams: {
    name: string;
    values: string[];
    multiSelection?: boolean | undefined;
  }[];
  appliedOptions: ExtendedFilterOption[];
} {
  const searchParamsExist = Object.keys(searchParams).length > 0;

  // Handle existing filters applied through browser URL query parameters
  let appliedOptions: ExtendedFilterOption[] = [];
  let searchParamsToRemove: string[] = [];
  let validSearchParams: {
    name: string;
    values: string[];
    multiSelection?: boolean;
  }[] = [];

  /**
   * 1. Remove invalid param values
   * 2. Update currentQueryParams with valida values
   * 3. Update appliedOptions with the selected options
   */
  if (searchParamsExist) {
    Object.keys(searchParams).forEach((searchParamName) => {
      // Check if param name is valid. If not, ignore
      if (!Object.keys(validQueryParameters).includes(searchParamName)) {
        return;
      }

      // If param name is valid, check if param values are valid. If not, remove from URL
      // Check if it corresponds to a range option (valid options are either arrays or objects)
      const isRangeOption = Array.isArray(
        validQueryParameters[searchParamName].options
      );

      if (!isRangeOption) {
        // Split search params string in case of multi-selection
        const searchParamValues = searchParams[searchParamName].split('-');
        searchParamValues.forEach((searchParamValue: string) => {
          if (
            !validQueryParameters[searchParamName].values.includes(
              searchParamValue
            )
          ) {
            Logger.error(
              '<FilterableProducts>: Query parameter value is invalid:',
              searchParamValue
            );
            searchParamsToRemove.push(searchParamName);
            return;
          }

          // Handle translations
          const originalQueryParamName = getOrinalQueryParamName(
            validQueryParameters,
            searchParamName,
            searchParamValue
          );
          const originalQueryParamValue = getOrinalQueryParamValue(
            validQueryParameters,
            searchParamName,
            searchParamValue
          );
          validSearchParams.push({
            name: originalQueryParamName,
            values: [originalQueryParamValue],
            multiSelection: true,
          });
          appliedOptions.push(
            validQueryParameters[searchParamName].options[searchParamValue][0]
          );
        });
        return;
      }

      // Handle translations
      const originalQueryParamName = getOrinalQueryParamName(
        validQueryParameters,
        searchParamName
      );

      if (isCushionDimensionOption(furnitureGroup, originalQueryParamName)) {
        if (
          !isNbCushionDimensionParamsValid(
            validQueryParameters,
            furnitureGroup,
            searchParams
          )
        ) {
          Logger.error(
            '<FilterableProducts>: Query parameters should include both height and length'
          );
          searchParamsToRemove.push(searchParamName);
          return;
        }

        // Check validity of param values
        if (
          !isCushionDimensionParamValueValid(
            validQueryParameters,
            furnitureGroup,
            searchParams
          )
        ) {
          Logger.error(
            '<FilterableProducts>: Query parameter value must be a number'
          );
          searchParamsToRemove.push(searchParamName);
          return;
        }

        // Convert search param string into number
        const searchParamValue = Number(searchParams[searchParamName]);

        // Check if param value is within min & max ranges
        if (
          !isValueInRange(
            validQueryParameters,
            searchParamName,
            searchParamValue
          )
        ) {
          Logger.error(
            '<FilterableProducts>: Query parameter value is out of range'
          );
          searchParamsToRemove.push(searchParamName);
          return;
        }

        const rangeQueryParams = [
          `${searchParamName}_min`,
          `${searchParamName}_max`,
        ];
        rangeQueryParams.forEach((rangeQueryParam) => {
          // Handle translations
          const originalRangeQueryParam = getOrinalRangeQueryParamName(
            validQueryParameters,
            searchParamName,
            rangeQueryParam
          );
          // If param value is valid, update API query params
          validSearchParams.push({
            name: originalRangeQueryParam,
            values: [searchParams[searchParamName]],
          });

          // Apply options and update UI
          const options = validQueryParameters[searchParamName].options.filter(
            (option: CfProductFilterOption) =>
              option.queryParam === originalRangeQueryParam &&
              Number(option.queryParamValue) === searchParamValue
          );
          if (options.length > 0) {
            appliedOptions = appliedOptions.concat(options);
          }
        });
        const dimensionsOptions = appliedOptions.filter((appliedOption) =>
          isCushionDimensionOption(furnitureGroup, appliedOption.queryParam)
        );
        const dimensionsOptionsGroupedByLabel: any = sortBy(
          groupBy(dimensionsOptions, 'label'),
          (options) => options.length
        );
        let optionsToRemove: CfProductFilterOption[] = [];
        Object.keys(dimensionsOptionsGroupedByLabel).forEach(
          (index: string) => {
            const options = dimensionsOptionsGroupedByLabel[index];
            if (dimensionsOptionsGroupedByLabel[index].length !== 4) {
              optionsToRemove = optionsToRemove.concat(options);
            } else {
              // Only keep options that match both height and length values
              appliedOptions = xorWith(
                appliedOptions,
                optionsToRemove,
                isEqual
              );
            }
          }
        );
        return;
      }

      // Split search params string and check validity of range values
      const searchParamValues = searchParams[searchParamName].split('-');
      if (searchParamValues.length !== 2) {
        Logger.error(
          '<FilterableProducts>: Query parameters values are invalid, they should follow this format: minValue-maxValue'
        );
        searchParamsToRemove.push(searchParamName);
        return;
      }

      // For prices and dimensions, check if param values are within min & max ranges
      if (
        !isPriceDimensionsInRange(
          validQueryParameters,
          searchParamName,
          searchParamValues
        )
      ) {
        Logger.error(
          '<FilterableProducts>: Query parameters values are out of range'
        );
        searchParamsToRemove.push(searchParamName);
        return;
      }

      const rangeQueryParams = [
        `${searchParamName}_min`,
        `${searchParamName}_max`,
      ];
      rangeQueryParams.forEach((rangeQueryParam, rangeIndex) => {
        // Handle translations
        const originalRangeQueryParam = getOrinalRangeQueryParamName(
          validQueryParameters,
          searchParamName,
          rangeQueryParam
        );
        // If param values are valid, update API query params
        validSearchParams.push({
          name: originalRangeQueryParam,
          values: [searchParamValues[rangeIndex]],
        });

        // Apply options and update UI
        const option = validQueryParameters[searchParamName].options.find(
          (option: CfProductFilterOption) =>
            option.queryParam === originalRangeQueryParam
        );
        option.queryParamValue = searchParamValues[rangeIndex];
        appliedOptions.push(option);
      });
    });
  }

  return { searchParamsToRemove, validSearchParams, appliedOptions };
}

/**
 * Check if the cushion dimension param values are valid
 * (should be numbers)
 */
function isCushionDimensionParamValueValid(
  validQueryParameters: ValidQueryParameters,
  furnitureGroup: string,
  searchParams: any
) {
  const dimensionsParams = getCushionDimensionParams(
    validQueryParameters,
    furnitureGroup,
    searchParams
  );
  const validDimensionsParams = filter(dimensionsParams, (value: string) =>
    Number(value)
  );
  return validDimensionsParams.length === 2;
}

/**
 * Check if the number of cushion dimension params is valid
 * (should be 2: height and length)
 */
function isNbCushionDimensionParamsValid(
  validQueryParameters: ValidQueryParameters,
  furnitureGroup: string,
  searchParams: any
) {
  const dimensionsParams = getCushionDimensionParams(
    validQueryParameters,
    furnitureGroup,
    searchParams
  );
  return dimensionsParams.length === 2;
}

function isPriceDimensionsInRange(
  validQueryParameters: ValidQueryParameters,
  searchParamName: string,
  searchParamValues: string[]
) {
  const minSearchRange = Number(searchParamValues[0]);
  const maxSearchRange = Number(searchParamValues[1]);
  const minValidRange = Number(validQueryParameters[searchParamName].values[0]);
  const maxValidRange = Number(validQueryParameters[searchParamName].values[1]);

  return minSearchRange >= minValidRange && maxSearchRange <= maxValidRange;
}

/**
 * Get cushions dimensions search parameter values
 */
function getCushionDimensionParams(
  validQueryParameters: ValidQueryParameters,
  furnitureGroup: string,
  searchParams: any
) {
  return filter(
    searchParams,
    (_searchParamValue: string, searchParamName: string) => {
      // Check if it corresponds to a range option (valid options are either arrays or objects)
      const isRangeOption = Array.isArray(
        validQueryParameters[searchParamName].options
      );
      return (
        isRangeOption &&
        isCushionDimensionOption(
          furnitureGroup,
          getOrinalQueryParamName(validQueryParameters, searchParamName)
        )
      );
    }
  );
}

/**
 * Get original query parameter value (not translated)
 */
function getOrinalQueryParamValue(
  validQueryParameters: ValidQueryParameters,
  searchParamName: string,
  searchParamValue: string
) {
  return validQueryParameters[searchParamName].options[searchParamValue][0]
    .queryParamValue;
}

/**
 * Get original range query parameter name (not translated)
 */
function getOrinalRangeQueryParamName(
  validQueryParameters: ValidQueryParameters,
  searchParamName: string,
  rangeQueryParam: string
) {
  return validQueryParameters[searchParamName].options.find(
    (option: CfProductFilterOption) => option.queryParamUrl === rangeQueryParam
  ).queryParam;
}

function isValueInRange(
  validQueryParameters: ValidQueryParameters,
  searchParamName: string,
  searchParamValue: number
) {
  const minValidRange = Number(validQueryParameters[searchParamName].values[0]);
  const maxValidRange = Number(validQueryParameters[searchParamName].values[1]);

  return searchParamValue >= minValidRange && searchParamValue <= maxValidRange;
}

/**
 * Get original query parameter name (not translated)
 */
function getOrinalQueryParamName(
  validQueryParameters: ValidQueryParameters,
  searchParamName: string,
  searchParamValue: string | null = null
) {
  return searchParamValue
    ? validQueryParameters[searchParamName].options[searchParamValue][0]
        .queryParam
    : validQueryParameters[searchParamName].options[0].queryParam;
}

/**
 * Initialize valid browser URL query parameters according to filters set in Contentful
 */
export function initValidURLQueryParameters(
  furnitureGroup: string,
  pricesDimensionsRanges: PricesDimensionsRanges | null,
  filters: CfProductFilter[]
) {
  const validQueryParameters: ValidQueryParameters = {};

  filters.forEach((filter) => {
    const optionsGroupedByQueryParam = groupBy(
      filter.filterOptions,
      (option) => option.queryParamUrl
    );

    Object.keys(optionsGroupedByQueryParam).forEach((queryParamName) => {
      const options = optionsGroupedByQueryParam[queryParamName];
      const optionsGroupedByQueryParamValue = groupBy(
        options,
        (option) => option.queryParamValueUrl
      );
      // Handle translations
      const originalQueryParamName = options[0].queryParam;
      // For prices and dimensions, merge min and max query parameters into one
      // i.e.: price_min & price_max -> price
      if (isRangeOption(originalQueryParamName)) {
        const groupName = queryParamName.split('_')[0];
        //Default to filter option range if pricesDimensionsRange is not available
        const rangeValue =
          pricesDimensionsRanges?.[originalQueryParamName] ??
          options[0].queryParamValue;
        const rangeOption = cloneDeep(options[0]);
        rangeOption.queryParamValue = rangeValue;
        let rangeOptions: any[] = [];
        if (isCushionDimensionOption(furnitureGroup, originalQueryParamName)) {
          // Keep all options for cushions dimensions since they are not exactly ranges, but specific values
          rangeOptions = options;
        } else {
          rangeOptions = [rangeOption];
        }
        // Set valid values using min & max ranges coming from API
        if (!validQueryParameters[groupName]) {
          validQueryParameters[groupName] = {
            values: [rangeValue],
            options: rangeOptions,
          };
        } else {
          validQueryParameters[groupName].values.push(rangeValue);
          if (
            isCushionDimensionOption(furnitureGroup, originalQueryParamName)
          ) {
            // Merge arrays
            validQueryParameters[groupName].options =
              validQueryParameters[groupName].options.concat(rangeOptions);
          } else {
            validQueryParameters[groupName].options.push(rangeOption);
          }
        }
      } else {
        validQueryParameters[queryParamName] = {
          values: options.map((option) => option.queryParamValueUrl),
          options: optionsGroupedByQueryParamValue,
        };
      }
    });
  });

  return validQueryParameters;
}

const rangesOptions = [
  'price_min',
  'price_max',
  'width_min',
  'width_max',
  'height_min',
  'height_max',
  'length_min',
  'length_max',
];

/**
 * Check if an option is a range option
 */
export function isRangeOption(
  option: CfProductFilterOption | FormattedOption | string
) {
  return typeof option === 'string'
    ? rangesOptions.includes(option)
    : rangesOptions.includes(option.queryParam);
}

/**
 * Check if an option is a checkbox option
 */
export function isCheckboxOption(
  option: CfProductFilterOption | FormattedOption
) {
  return option.isBoolean;
}

/**
 * Check if an option is a cushion dimension option
 */
function isCushionDimensionOption(furnitureGroup: string, paramName: string) {
  return (
    furnitureGroup === 'others' &&
    ['height_min', 'height_max', 'length_min', 'length_max'].includes(paramName)
  );
}
