import { Range } from 'rc-slider';

import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import { useLocale } from 'mycs/shared/state/LocaleContext';

import styles from './RangeSlider.scss';

interface Props {
  initialRange: number[];
  minValue: number;
  maxValue: number;
  onChange?: any;
  type: string; //'price' | 'dimensions';
  name: string;
  label: string;
  multipleSliders?: boolean;
}

export default function RangeSlider({
  initialRange,
  minValue,
  maxValue,
  name,
  type,
  multipleSliders,
  label,
  onChange = () => null,
}: Props) {
  const { locale } = useLocale();
  const marks = getMarks();
  const sliderProps = {
    value: initialRange,
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{formatTitle(initialRange)}</div>

      <div className={styles.rangeSliderContainer}>
        <Range
          key={name}
          className={styles.rcSlider}
          defaultValue={initialRange}
          min={minValue}
          max={maxValue}
          marks={marks}
          allowCross={false}
          onChange={onChangeHandler}
          pushable={1}
          {...sliderProps}
        />
      </div>
    </div>
  );

  /**
   * Return a formatted list of marks
   */
  function getMarks(): any {
    return [minValue, maxValue].reduce((marks: any, value: any, i: number) => {
      marks[i] = {
        value,
        label: <div className={styles.label}>{formatLabel(value)}</div>,
      };
      return marks;
    }, {});
  }

  /**
   * Compute label corresponding to value
   */
  function formatLabel(value: number): string {
    switch (type) {
      case 'price':
        return I18nUtils.localizePrice(value, 0, locale);
      case 'dimensions':
        return `${Math.ceil(value / 10)} cm`;
      default:
        return value.toString();
    }
  }

  /**
   * Compute title
   */
  function formatTitle(rangeValue: number[]): string {
    return multipleSliders
      ? `${label}: ${formatLabel(rangeValue[0])} - ${formatLabel(
          rangeValue[1]
        )}`
      : I18nUtils.localize(locale, 'From %r0 to %r1')
          .replace('%r0', formatLabel(rangeValue[0]))
          .replace('%r1', formatLabel(rangeValue[1]));
  }

  /**
   * On slider change, set current range
   */
  function onChangeHandler(rangeValue: number[]) {
    if (onChange) {
      onChange(name, rangeValue);
    }
  }
}
