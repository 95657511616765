import { PureComponent } from 'react';
import { CfPageMain } from '@mycs/contentful';
import AnalyticsService from 'mycs/shared/services/AnalyticsService/AnalyticsService';

type Props = {
  data: CfPageMain;
  locale: string;
};

export class TrackMainPageSubcategories extends PureComponent<Props> {
  /**
   * Track the categories and sub-categories pages for FB
   */
  track(prevData?: CfPageMain) {
    const { data } = this.props;
    // Categories (pages that have subcategories)
    // are tracked as "ViewContent"
    if (!prevData && data && data.subcategories) {
      AnalyticsService.trackFacebook('ViewContent', this.props.locale);
    }
  }

  /**
   * Send a tracking event on mount
   */
  componentDidMount() {
    this.track();
  }

  /**
   * Track the page update
   */
  componentDidUpdate(prevProps: Props) {
    this.track(prevProps.data);
  }

  render() {
    return null;
  }
}
