import { useState } from 'react';
import styles from './ProductsCarousel.scss';
import MediaPreview from 'mycs/shared/components/MediaPreview/MediaPreview';
import { CfGalleryItem } from '@mycs/contentful';
import { useDevice } from 'mycs/router/DeviceContext';
import ProductModal from 'mycs/shared/components/ProductModal/ProductModal';
import Carousel from 'mycs/shared/components/Carousel/Carousel';
import { useDesigns } from 'mycs/hooks/useDesigns';
import { useItemsWithDesigns } from 'mycs/hooks/useItemsWithDesigns';
import UIUtils from 'mycs/shared/utilities/UIUtils/UIUtils';
import classNames from 'classnames';

interface ContentData {
  _id: string;
  _contentType: string;
  title: string;
  items: CfGalleryItem[];
}

interface Props {
  content: ContentData;
}

export default function ProductsCarousel({ content }: Props) {
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const items = useItemsWithDesigns(content?.items);
  const designs = useDesigns(items.flatMap((item) => item.uuids ?? []));
  const designDict: { [key: string]: boolean } = {};

  items.forEach((item) => {
    if (item.uuids) {
      const uuids = item.uuids;
      uuids.forEach((uuid: string) => {
        if (!designDict[uuid]) {
          const design = designs.find((d) => d.uuid === uuid);
          if (design && !item.designs?.some((d) => d.uuid === design.uuid)) {
            item.designs = [...(item.designs ?? []), design];
            designDict[uuid] = true;
          }
        }
      });
    }
  });

  const { hasPhoneDimensions, isTablet, isTabletPortrait } = useDevice();
  let imagesPerRow = 5;
  if (hasPhoneDimensions) imagesPerRow = 2;
  if (isTablet || isTabletPortrait) imagesPerRow = 3;

  const closeModal = () => {
    UIUtils.scrollToElement('#react-root', { duration: 0.1 });
    setSelectedItemIndex(-1);
  };

  const displayItem = (item: CfGalleryItem, items: CfGalleryItem[]) => {
    const activeIndex = items.findIndex(({ _id }: any) => _id === item._id);
    setSelectedItemIndex(activeIndex);
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{content.title}</h3>
      <ProductModal
        activeItem={items[selectedItemIndex]}
        activeIndex={selectedItemIndex}
        totalItems={items.length - 1}
        isOpen={selectedItemIndex >= 0}
        onClose={closeModal}
        onChange={(index) => setSelectedItemIndex(index)}
        className={styles.modal}
      />
      <Carousel
        slidesToShow={imagesPerRow}
        slidesToScroll={1}
        className={styles.slider}
      >
        {items?.map((item) => (
          <div
            onClick={() => (item.fullImage ? displayItem(item, items) : null)}
            key={item.slug}
            className={classNames(styles.mediaPreview, {
              [styles.nonClickable]: !item.fullImage,
            })}
          >
            <MediaPreview
              imageSrc={item.smallImage?.url}
              imageAlt={item.smallImage?.description}
              imageTitle={item.smallImage?.title}
              classes={styles.mediaPreviewContainer}
              imageSizes={'10vw'}
              imageHeight={200}
              imageWidth={300}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}
