import { useState } from 'react';
import classNames from 'classnames';

import { CfFurnitureType, CfFurnitureTypeCard } from '@mycs/contentful';

import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Alink from 'mycs/shared/components/Alink/Alink';
import Button from 'mycs/shared/components/Button/Button';
import Carousel from 'mycs/shared/components/Carousel/Carousel';
import cfg from 'mycs/config';
import DesignApiService from 'mycs/shared/services/DesignApiService/DesignApiService';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import Scrollable from 'mycs/shared/components/Scrollable/Scrollable';
import SmartImage from 'mycs/shared/components/SmartImage/SmartImage';

import styles from './FurnitureTypeCard.scss';

export type ExtendedFurnitureType = CfFurnitureType & {
  imageUrl: string;
};

type Props = {
  content: CfFurnitureTypeCard;
};

export default function FurnitureTypeCard({ content }: Props) {
  const { locale } = useLocale();
  const { hasPhoneDimensions, isTablet } = useDevice();
  const slidesToShow = hasPhoneDimensions || isTablet ? 3 : 4;
  const furnitureTypes = getFurnitureTypes(content, hasPhoneDimensions);
  const [activeFurnitureType, setActiveFurnitureType] =
    useState<ExtendedFurnitureType | null>(furnitureTypes[0] ?? null);

  const { title, text, furnitureType } = content;

  if (!furnitureTypes || !activeFurnitureType) return null;

  const trackName = 'inspirationClick';
  const trackData = {
    action: 'Click',
    uuid: activeFurnitureType.uuid,
    pageCategory: title,
  };

  const customizeLink = RelativeUrlService.getConfiguratorUrl(
    furnitureType,
    activeFurnitureType.uuid,
    locale
  );

  return (
    <div className={styles.furnitureTypeCard}>
      <div className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.text}>{text}</p>
        <div className={styles.mainImage}>
          <Alink
            trackName={trackName}
            trackData={trackData}
            href={customizeLink}
          >
            <SmartImage
              alt={activeFurnitureType.name}
              title={activeFurnitureType.name}
              src={activeFurnitureType.imageUrl}
            />
          </Alink>
        </div>
        <div className={styles.scrollerContainer}>
          <h3>{I18nUtils.localize(locale, 'Start configuring:')}</h3>

          {hasPhoneDimensions ? (
            <div className={styles.scroller}>
              <Scrollable>
                {furnitureTypes.map((item) => (
                  <Alink
                    key={item.name}
                    href={isSelected(item) ? customizeLink : undefined}
                    className={classNames(styles.furnitureType, {
                      [styles.active]: isSelected(item),
                    })}
                    trackName={isSelected(item) ? trackName : undefined}
                    trackData={isSelected(item) ? trackData : undefined}
                    onClick={(e) => onSelectFurnitureType(e, item)}
                    text={item.uuid}
                  >
                    <div className={styles.furnitureTypeImage}>
                      <SmartImage
                        alt={item.name}
                        title={item.name}
                        src={item.imageUrl}
                      />
                    </div>

                    <div className={styles.furnitureTypeName}>{item.name}</div>
                  </Alink>
                ))}
              </Scrollable>
            </div>
          ) : (
            <div className={styles.carousel}>
              <Carousel
                slidesToShow={slidesToShow}
                slidesToScroll={slidesToShow}
                arrows={furnitureTypes.length > slidesToShow}
                dots={false}
                infinite={false}
              >
                {furnitureTypes.map((item) => (
                  <Alink
                    key={item.name}
                    href={isSelected(item) ? customizeLink : undefined}
                    className={classNames(styles.furnitureType, {
                      [styles.active]: isSelected(item),
                    })}
                    trackName={isSelected(item) ? trackName : undefined}
                    trackData={isSelected(item) ? trackData : undefined}
                    onClick={(e) => onSelectFurnitureType(e, item)}
                    text={item.uuid}
                  >
                    <div className={styles.furnitureTypeImage}>
                      <SmartImage
                        alt={item.name}
                        title={item.name}
                        src={item.imageUrl}
                      />
                    </div>

                    <div className={styles.furnitureTypeName}>{item.name}</div>
                  </Alink>
                ))}
              </Carousel>
            </div>
          )}
        </div>

        <div className={styles.buttonContainer}>
          <Button
            href={customizeLink}
            className={styles.button}
            isPrimaryCta
            text="Customize now"
            trackName={trackName}
            trackData={trackData}
            isFullWidth
          />
        </div>
      </div>
    </div>
  );

  function isSelected(item: ExtendedFurnitureType) {
    return item._id === activeFurnitureType?._id;
  }

  function onSelectFurnitureType(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    furnitureType: ExtendedFurnitureType
  ) {
    if (furnitureType !== activeFurnitureType) {
      e.preventDefault();

      // A timeout is needed to avoid the href to appear right after a click
      setTimeout(() => {
        setActiveFurnitureType(furnitureType);
      }, 10);
    }
  }

  /**
   * Extend the Card with design images (including fallbacks from Contentful)
   */
  function getFurnitureTypes(
    content: Props['content'],
    hasPhoneDimensions: boolean
  ): ExtendedFurnitureType[] {
    const furnitureTypes = (content?.furnitureTypes || []).map((fType) => ({
      ...fType,
      imageUrl: getImageUrl(fType),
    }));

    // Exclude furniture types without a mobile configurator
    if (hasPhoneDimensions) {
      //FIXME: could be hidden through CSS
      return furnitureTypes.filter(
        () =>
          content.furnitureType &&
          cfg.mobileConfiguratorTypes.includes(content.furnitureType)
      );
    }

    return furnitureTypes;
  }

  function getImageUrl(furnitureType: CfFurnitureType): string {
    if (furnitureType.image?.url) {
      // Use a CF image
      return furnitureType.image.url;
    }

    if (!furnitureType.uuid) {
      return '';
    }

    // Use whatever image is first in the Design API w/o a separate request
    return DesignApiService.getProductImageUrl(furnitureType.uuid);
  }
}
