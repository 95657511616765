import Anchor from 'mycs/shared/components/Anchor/Anchor';
import styles from './AnchorLinkHeader.scss';
import { CfAnchors } from '@mycs/contentful';

interface Props {
  content?: CfAnchors;
}

export default function AnchorLinkHeader({ content }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.anchorColumn}>
        {content?.anchors
          ?.slice(0, Math.ceil(content?.anchors?.length / 2))
          .map(({ title, anchorId }) =>
            title ? (
              <Anchor key={title} destination={`#${anchorId}`} text={title} />
            ) : null
          )}
      </div>
      <div className={styles.anchorColumn}>
        {content?.anchors
          ?.slice(Math.ceil(content?.anchors?.length / 2))
          .map(({ title, anchorId }) => (
            <Anchor key={title} destination={`#${anchorId}`} text={title} />
          ))}
      </div>
    </div>
  );
}
